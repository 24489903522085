import { createGlobalStyle } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
    }

    #root{
        margin:0 auto;
    }

    *::after {
      box-sizing: border-box;
    }

    html {
      font-size: 100%;
    }

    body {
      font-family: ${({ theme }) => theme.fonts.sans};
      height: 100vh;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-x: hidden;
    }
`;
