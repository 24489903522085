import styled from "styled-components";
import { SpacerProps } from "./index";

export const StyledSpacer = styled.div<SpacerProps>`
  margin-top: ${({ mt }) => mt.xs / 16}rem;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin-top: ${({ mt }) => mt.sm && `${mt.sm / 16}rem`};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ mt }) => mt.md && `${mt.md / 16}rem`};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-top: ${({ mt }) => mt.lg && `${mt.lg / 16}rem`};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: ${({ mt }) => mt.xl && `${mt.xl / 16}rem`};
  }
`;
