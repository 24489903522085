import { styled } from "styled-components";

export const BannerHeadline = styled.h3`
  font-size: 16px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 20px;
  }
`;
export const BannerText = styled.p`
  font-size: 14px;
  font-weight: 300;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
  }
`;

export const BannerHighlight = styled.h4`
  font-size: 14px;
  font-weight: bold;
  margin: 5px auto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 16px;
    margin: 5px auto 10px;
  }
`;
