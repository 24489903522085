import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import App from "./App";
import "./global.css";
import {
  EL_DUENDE_DOMAIN,
  GTM_DUENDE_AUTH,
  GTM_DUENDE_PREVIEW,
  GTM_ID_DUENDE,
  GTM_ID_TABLAO,
  PUBLIC_POSTHOG_KEY,
  PUBLIC_POSTHOG_HOST,
} from "./env";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

let tagManagerArgs;

if (window.location.hostname === EL_DUENDE_DOMAIN) {
  tagManagerArgs = {
    gtmId: GTM_ID_DUENDE,
    auth: GTM_DUENDE_AUTH,
    preview: GTM_DUENDE_PREVIEW,
  };
} else {
  tagManagerArgs = {
    gtmId: GTM_ID_TABLAO,
  };
}

TagManager.initialize(tagManagerArgs);

import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: PUBLIC_POSTHOG_HOST,
};

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={PUBLIC_POSTHOG_KEY} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
