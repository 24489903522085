import { ReactNode } from "react";
import { createStyledBreakpointsTheme } from "styled-breakpoints";
import { ThemeProvider } from "styled-components";

import { colors } from "./colors";
import { fonts } from "./fonts";
import { textSizes } from "./textSizes";

export const breakpoints = {
  sm: "0px",
  xs: "450px",
  md: "640px",
  lg: "1024px",
  xl: "1200px",
  xxl: "1440px",
} as const;

export const defaultTheme = {
  getSpacing: (...spacings: number[]) => {
    return spacings.map((spacing) => `${spacing / 16}rem`).join(" ");
  },
  colors,
  textSizes,
  fonts,
  ...createStyledBreakpointsTheme({ breakpoints }),
};

export const Theme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
);
