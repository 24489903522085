import { Link } from "react-router-dom";
import { StyledLegals } from "./styles";
import { useContext } from "react";
import { LanguageContext } from "providers/LangProvider";
import { FormattedMessage } from "react-intl";

export const LegalsFooter = () => {
  const { locale } = useContext(LanguageContext);
  const getUrl = () => {
    if (locale === "es") {
      return "https://tablaocordobes.es/es/legal#privacy-policy";
    }
    if (locale === "ca") {
      return "https://tablaocordobes.es/ca/legal#privacy-policy";
    }
    return "https://tablaocordobes.es/legal#privacy-policy";
  };

  return (
    <StyledLegals>
      <Link to="/terms-and-conditions">
        <FormattedMessage defaultMessage="Terms and conditions" id="ZibE+c" />
      </Link>
      <a target="_blank" href={getUrl()}>
        <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
      </a>
    </StyledLegals>
  );
};
