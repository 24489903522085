import styled from "styled-components";
import { FlexContent } from "../FlexContent/FlexContent";

export const StyledCounter = styled(FlexContent)`
  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 0;
  }
`;
