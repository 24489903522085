export const colors = {
  yellow: "#eaa945",
  yellow40: "#FFF6E9",
  red: "#EB3F25",
  navy: "#1e215e",
  orange: "#ff6a00",
  beige: "#F9F5F5",
  black: "#000",
  white: "#fff",
  gray100: "#242424",
  gray80: "#4f4c4c",
  gray60: "#666",
  gray40: "#9b9b9b",
  gray20: "#E2E2E2",
  gray10: "#f2efef",
  gray8: "#f8f4f4",
  gray5: "#f3f3f3",
  gray3: "#f8f8f8",
};

export type ThemeColors = typeof colors;
