export const ContentEs = () => (
  <>
    <h2>Términos y condiciones</h2>
    <div>
      <p>
        En cumplimiento de la Ley 34/2002 de Servicios de la Sociedad de la
        Información y del Comercio Electrónico (LSSICE), le informamos que{" "}
        <a href="https://tablaocordobes.es" target="_self">
          https://tablaocordobes.es
        </a>{" "}
        es dominio de Internet y marca registrada por Cadame, S.L. (en adelante,
        Tablao Flamenco Cordobes), C.I.F. B60132263, con domicilio social en la
        La Rambla 35 de Barcelona e inscrita en el Reg. Mercantil Barcelona,
        Folio 182, Tomo 24966, inc 1ª Hoja B-81666 C. Asimismo TABLAO FLAMENCO
        CORDOBES.
      </p>
      <p>
        Condiciones generales de contratación entre Tablao Flamenco Cordobes y
        el usuario:
      </p>
      <ol>
        <li>
          <p>
            El usuario declara que es mayor de edad (mayor de 18 años) y dispone
            de capacidad legal necesaria para contratar los servicios ofertados
            en la web de Tablao Flamenco Cordobes, de acuerdo con las
            condiciones más abajo desglosadas, las cuales declara comprender y
            aceptar.
          </p>
          <p>
            En el caso de contratación por menores de edad, se requiere la
            autorización de los padres o tutores para poder disfrutar del
            servicio contratado. El usuario es el único responsable de la
            veracidad y exactitud de los datos proporcionados a Tablao Flamenco
            Cordobes en el proceso de reserva.
          </p>
          <p>
            El usuario se compromete a utilizar los servicios y contenidos en
            nuestro sitio web de conformidad con la Ley y los términos y
            condiciones generales y particulares de los servicios que ofrece El
            Duende by Tablao Cordobes en cada momento, debiendo abstenerse de
            emplearlos para:
          </p>
          <p>
            (a) Transmitir a terceros no autorizados su nombre de usuario y su
            contraseña, debiendo comunicar inmediatamente a El Duende by Tablao
            Cordobes el acceso por parte de un usuario no autorizado a dicha
            información.
          </p>
          <p>
            (b) Introducir en la red programas de datos susceptibles de provocar
            daños en los sistemas informáticos de Tablao Flamenco Cordobes, sus
            proveedores o terceros usuarios de la red interna o de este sitio
            web.
          </p>
          <p>
            (c) Realizar actividades ilícitas, contrarias a la buena fe, a las
            costumbres, a la moral o al orden público.
          </p>
          <p>
            (d) Realizar actividades que constituyan una infracción de la
            regulación sobre propiedad intelectual e industrial o cualquier otra
            norma del ordenamiento jurídico aplicable.
          </p>
          <p>
            (e) Reproducir, duplicar, vender o explotar para fines comerciales
            cualquier contenido del sitio web.
          </p>
          <p>
            (f) Difundir contenidos de carácter racista, xenófobo, pornográfico,
            de apología del terrorismo y/o que atenten contra los derechos
            humanos.
          </p>
        </li>
        <li>
          <p>
            Al registrarse en Tablao Flamenco Cordobes, el usuario escogerá un
            nombre de usuario y contraseña, que posteriormente le serán
            confirmados por e-mail. El usuario deberá custodiar debidamente las
            claves y contraseñas facilitadas para su acceso como usuario
            registrado, dado que son de carácter personal y confidencial, siendo
            el usuario responsable de su uso por una tercera persona a la que
            dicho usuario se las haya facilitado.
          </p>
        </li>
        <li>
          <p>
            El usuario es el responsable de facilitar sus datos correctamente en
            los procesos de contratación establecidos en la página web de El
            Duende by Tablao Cordobes, debiendo prestar especial atención en
            cuanto a la introducción de los datos relativos a la dirección de
            entrega de los pedidos y documentos, así como los nombres de las
            terceras personas respecto a las cuales introduzca sus datos, previa
            autorización de dichas terceras personas, debiendo en todo momento
            el usuario introducir todos los datos de identificación de modo
            correcto y tal y como aparecen en el DNI o pasaporte.
          </p>
        </li>
        <li>
          <p>
            Tablao Flamenco Cordobes es titular de la base de datos generada con
            los datos de carácter personal suministrados por los usuarios en el
            proceso de compra de productos a través de nuestra página web. El
            Duende by Tablao Cordobes se compromete al cumplimiento de su
            obligación de secreto de los datos de carácter personal y de su
            deber de tratarlos con confidencialidad, y a tal fin El Duende by
            Tablao Cordobes adoptará las medidas necesarias para evitar su
            alteración, pérdida, tratamiento o acceso no autorizado, habida
            cuenta en todo momento del estado de la tecnología con arreglo a lo
            previsto en la legislación vigente en España en materia de
            protección de datos de carácter personal.
          </p>
          <p>
            El usuario acepta que los datos personales facilitados en el proceso
            de compra de nuestros productos sean incorporados a un fichero
            automatizado, cuya titularidad corresponde a El Duende by Tablao
            Cordobes, con la finalidad de prestar adecuadamente el servicio
            solicitado, dar cumplimiento a los requisitos de facturación,
            contabilidad y auditoría de la compañía, realizar análisis
            estadísticos, verificar tarjetas de crédito y otras tarjetas de
            pago, así como realizar encuestas de satisfacción y comunicaciones
            comerciales por correo electrónico sobre otros productos que se
            comercializan a través de la página web de El Duende by Tablao
            Cordobes y que Tablao Flamenco Cordobes considere que podrían ser
            del interés del usuario, por ser similares a los contratados. En
            caso de que el usuario no desee recibir comunicaciones comerciales
            por medios electrónicos puede dirigirse en cualquier momento a El
            Duende by Tablao Cordobes enviando un correo electrónico a la
            siguiente dirección{" "}
            <a href="mailto:tablao@tablaocordobes.com" target="_self">
              tablao@tablaocordobes.com
            </a>
            . El usuario también podrá ejercer sus derechos de acceso,
            rectificación, cancelación y oposición mediante comunicación
            escrita, adjuntando fotocopia del DNI o documento identificativo
            equivalente, ante TABLAO Flamenco CORDOBES, S.L. en La Rambla 33,
            08002 (Barcelona).
          </p>
          <p>
            El documento en que se formalice el contrato relativo al producto
            contratado será archivado en una base de datos accesible únicamente
            por TABLAO Flamenco CORDOBES, S.L. como responsable de la misma. El
            cliente podrá identificar y corregir los errores padecidos en la
            introducción de datos comunicándolo a Tablao Flamenco Cordobes,
            utilizando el trámite referido anteriormente en esta cláusula.
          </p>
          <p>
            El usuario responderá, en cualquier caso, de la veracidad de los
            datos facilitados y se responsabilizará de comunicar a El Duende by
            Tablao Cordobes cualquier modificación en los mismos, reservándose
            El Duende by Tablao Cordobes el derecho a excluir de los servicios
            registrados a todo usuario que haya facilitado datos falsos, sin
            perjuicio de las demás acciones que procedan en Derecho.
          </p>
        </li>
        <li>
          <p>
            Todos los contenidos, marcas, logos, dibujos etc. que figuran en las
            webs de Tablao Flamenco Cordobes, se hallan protegidos por los
            derechos de propiedad intelectual e industrial que son expresamente
            reservados por Tablao Flamenco Cordobes o, en su caso, por las
            personas o empresas que figuran como autores o titulares de los
            derechos. La violación de los anteriores derechos será perseguida de
            conformidad con la legislación vigente.
          </p>
          <p>
            Por lo tanto, queda prohibida la reproducción, explotación,
            alteración, distribución o comunicación pública por cualquier título
            o medio de la totalidad de los contenidos de las páginas web de El
            Duende by Tablao Cordobes para usos diferentes de la legítima
            información o contratación por los usuarios de los servicios
            ofertados. En todo caso será necesario el consentimiento previo por
            escrito de Tablao Flamenco Cordobes.
          </p>
        </li>
        <li>
          <p>
            Los links o enlaces con otras páginas web que aparezcan en las
            páginas de Tablao Flamenco Cordobes se ofrecen a efectos meramente
            informativos al usuario, por lo que Tablao Flamenco Cordobes no se
            responsabiliza sobre los productos, servicios o contenidos que se
            oferten o suministren en las páginas de destino ubicados en otro
            dominio.
          </p>
        </li>
        <li>
          <p>
            Cookies: En Tablao Flamenco Cordobes recoge mediante el uso de
            cookies y utiliza información de los usuarios para analizar su
            conducta al navegar en dicha página web y de esta manera
            proporcionar sus servicios de manera más eficiente e introducir
            mejoras.
          </p>
          <p>
            Una cookie es un pequeño archivo de datos que es transferido al
            disco duro de su ordenador. Tablao Flamenco Cordobes utiliza la
            tecnología de cookies para recopilar datos adicionales sobre el uso
            del sitio web. La navegación por las páginas web de El Duende by
            Tablao Cordobes implica consentir la instalación de las mismas
            (excepto en la medida en que el usuario haya modificado la
            configuración del navegador para rechazar cookies).
          </p>
          <p>
            Enlaces: Con el fin de proveer una publicidad más adecuada a los
            usuarios y mejorar las ofertas, Tablao Flamenco Cordobes puede
            conservar información sobre cómo el usuario interactúa con enlaces
            que aparecen en la página web o en las notificaciones, redirigiendo
            clics o por otros medios.
          </p>
        </li>
        <li>
          <p>
            Si cualquier cláusula incluida en estas condiciones generales fuese
            declarada, total o parcialmente, nula o ineficaz, tal nulidad o
            ineficacia afectará tan sólo a dicha disposición o a la parte de la
            misma que resulte nula o ineficaz, subsistiendo las condiciones
            generales en todo lo demás, teniéndose tal disposición, o la parte
            de la misma que resultase afectada, por no puesta.
          </p>
        </li>
        <li>
          <p>
            El usuario acepta que la legislación aplicable al funcionamiento de
            este servicio es la española. Serán competentes para conocer de las
            divergencias que se deriven de la interpretación o aplicación de
            este clausulado los Jueces y Tribunales del domicilio del
            consumidor.
          </p>
        </li>
        <li>
          <p>
            Los precios finales indicados en pantalla incluyen el I.V.A. y
            cualesquiera otros impuestos o tasas que fuera de aplicación, al
            tipo vigente en el momento de la contratación.
          </p>
        </li>
        <li>
          <p>
            Todas las reservas realizadas mediante la página web de{" "}
            <a href="https://elduendebarcelona.com/" target="_self">
              elduendebarcelona.com
            </a>{" "}
            podrán ser canceladas durante los 14 siguientes días hábiles a la
            fecha de la compra tal y como se especifica en la legislación
            vigente Texto Refundido de la Ley General para la Defensa de los
            Consumidores y Usuarios y otras leyes complementarias, aprobado por
            el RD 1/2007. La devolución del importe correspondiente será abonada
            al cliente dentro de los 30 días siguientes la confirmación de
            anulación de la reserva utilizando el mismo método de pago utilizado
            por el cliente. No se aceptarán bajo ninguna circunstancia
            cancelaciones o modificaciones si la petición de cancelación o
            modificación ha sido recibida por TABLAO Flamenco CORDOBES, S.L.
            menos de 48 horas antes de la hora del servicio.
          </p>
        </li>
        <li>
          <p>
            Política Promocional: TABLAO Flamenco CORDOBES, S.L realiza
            regularmente promociones que afectan al precio de venta al público,
            las cuales pueden ser modificadas y/o canceladas por parte de la
            empresa en cualquier momento. Toda promoción que se realice no será
            acumulable a otras promociones y/o Tarifas Especiales vigentes. Los
            productos en Promoción, Galas Fin de Año, Palau, y/o Producto ruleta
            no podrán ser cancelados y no darán derecho a devolución del importe
            de la adquisición del mismo, dado su carácter promocional.
          </p>
        </li>
        <li>
          <p>
            Para cualquier sugerencia, consulta, queja o reclamación el usuario
            debe dirigirse a CADAME, S.L. a la dirección La Rambla 35 08002
            Barcelona, o bien por medio del teléfono 933175711 o mediante
            comunicación electrónica a{" "}
            <a href="mailto:tablao@tablaocordobes.com" target="_self">
              tablao@tablaocordobes.com
            </a>
            .
          </p>
        </li>
      </ol>
    </div>
  </>
);
