import { PriceType, SingleShowType } from "../api/booking";
import { EVENT_TYPES_PRIORITY } from "../consts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { showAndDinner } from "../i18n/content";
dayjs.extend(utc);

export type ParsedCalendarType = {
  [key: string]: {
    [key: string]: SingleShowType[];
  };
};
export const parseCalendar = ({
  data,
  guests,
  isDuende,
}: {
  data: SingleShowType[];
  guests: number;
  isDuende: boolean;
}): ParsedCalendarType => {
  const result = {};
  const duendeDates = {};

  data.forEach((item) => {
    const date = item.date.substring(0, 10);
    const showTime = getShowTime(item.showTime);

    if (isDuende) {
      //if item.date is in the past, skip it
      if (
        dayjs(item.date).utc().add(1, "hour").format("YYYY-MM-DD HH:mm") <
        dayjs().add(30, "m").format("YYYY-MM-DD HH:mm")
      ) {
        return;
      }
      if (item.totalTicketsAvailable < guests) {
        return;
      }

      const prices = item.prices.filter(
        (price) => price.ticketsAvailable >= guests,
      );

      if (prices.length > 0) {
        if (!result[date]) {
          result[date] = {};
        }
        if (!result[date][showTime]) {
          result[date][showTime] = [];
        }
        result[date][showTime].push({
          ...item,
          date: dayjs(item.date)
            .utc()
            .add(1, "hour")
            .format("YYYY-MM-DD HH:mm"),
          showTime,
          prices,
        });

        result[date][showTime].sort((a, b) => {
          const aPriority = EVENT_TYPES_PRIORITY.indexOf(a.eventType);
          const bPriority = EVENT_TYPES_PRIORITY.indexOf(b.eventType);
          return aPriority - bPriority;
        });
      }
    } else {
      //save info when Duende has free spots
      if (item.place === "duende") {
        const prices = item.prices.filter(
          (price) => price.ticketsAvailable >= guests,
        );
        if (prices.length > 0) {
          if (!duendeDates[date]) {
            duendeDates[date] = [];
          }
          duendeDates[date].push(showTime);
          return;
        }
      } else {
        // if item.date is in the past -30 minutes, skip it
        if (
          dayjs(item.date).utc().add(1, "hour").format("YYYY-MM-DD HH:mm") <
          dayjs().add(30, "m").format("YYYY-MM-DD HH:mm")
        ) {
          return;
        }

        // if single person is not allowed for dinner, skip it
        if (
          item.eventType === showAndDinner &&
          guests === 1 &&
          !item.allowSinglePerson
        ) {
          return;
        }

        // for shows different than show_and_drink, skip if there are not enough tickets
        if (
          item.eventType !== "show_and_drink" &&
          guests > item.prices[0].ticketsAvailable
        ) {
          return;
        }

        if (!result[date]) {
          result[date] = {};
        }
        if (!result[date][item.eventType]) {
          result[date][item.eventType] = [];
        }

        result[date][item.eventType].push({
          ...item,
          date: dayjs(item.date)
            .utc()
            .add(1, "hour")
            .format("YYYY-MM-DD HH:mm"),
          showTime,
        });
      }
    }
  });

  if (!isDuende) {
    Object.keys(result).forEach((date) => {
      const events = [];
      result[date].show_and_drink.forEach((event, index) => {
        // parse events with not enough tickets
        if (guests > event.prices[0].ticketsAvailable) {
          if (
            !duendeDates[date] ||
            !isTimeAvailable(event.showTime, duendeDates[date])
          ) {
            // remove element from the events array
            events.splice(index, 1);
            return;
          } else {
            // if there are free spots in Duende, add a second location
            events.push({
              ...event,
              hasSecondLocation: true,
            });
          }
        } else {
          events.push(event);
        }
      });

      // if no show_and_drink events are available for a specific date, remove the key
      if (events.length === 0) {
        delete result[date].show_and_drink;
      } else {
        result[date].show_and_drink = events;
      }

      // if no events are available for a specific date, remove the date
      if (Object.keys(result[date]).length === 0) {
        delete result[date];
      }
    });
  }

  // returned result should be sorted by date in ascending order
  return Object.keys(result)
    .sort()
    .reduce((acc, key) => {
      acc[key] = result[key];
      return acc;
    }, {});
};

export const findSmallestPrice = (prices: PriceType[]): PriceType => {
  let smallestPrice = prices[0];
  prices.forEach((price) => {
    if (price.price < smallestPrice.price) {
      smallestPrice = price;
    }
  });
  return smallestPrice;
};

export const getShowTime = (time: string): string =>
  dayjs()
    .set("hour", Number(time.split(":")[0]) + 1) // time from API comes in UTC+0
    .set("minute", Number(time.split(":")[1]))
    .format("HH:mm");

function convertToMinutes(time: string): number {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

const isTimeAvailable = (targetTime: string, hours: string[]): boolean => {
  const targetMinutes = convertToMinutes(targetTime);
  for (const hour of hours) {
    const hourMinutes = convertToMinutes(hour);

    // Check if the targetTime is within 1.5 hours (90 minutes) range of the current hour
    if (Math.abs(targetMinutes - hourMinutes) <= 90) {
      return true; // Time is available
    }
  }
  return false; // Time is not available
};
