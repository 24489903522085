import { PageHeadline } from "../../typography.styles";
import { FormattedMessage } from "react-intl";
import { BookingContent, StyledBooking } from "./styles";
import { Header } from "../../Header/Header";
import { PaymentSuccess } from "../../paymentResult/success";
import { PaymentError } from "../../paymentResult/error";
import { LangSwitcher } from "../../languageSwitcher";

export const PaymentResult = ({ type }: { type: string }) => {
  return (
    <StyledBooking>
      <div>
        <Header backgroundImage="/images/dancers.png">
          <>
            <PageHeadline>
              <FormattedMessage
                defaultMessage="Book Flamenco show in Barcelona"
                id="Cj9B8x"
              />
            </PageHeadline>
            <LangSwitcher />
          </>
        </Header>
        <BookingContent direction="column" alignItems="flex-start">
          {type === "error" ? <PaymentError /> : <PaymentSuccess />}
        </BookingContent>
      </div>
    </StyledBooking>
  );
};
