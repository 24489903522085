import { bookingApi } from "api/booking";
import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import { STEP_CALENDAR } from "../../../consts";
import { BookingContext } from "../../../providers/BookingProvider";
import { Footer } from "../../footer";
import { Header } from "../../Header/Header";
import { LangSwitcher } from "../../languageSwitcher";
import { Loader } from "../../loader";
import { PageHeadline } from "../../typography.styles";
import {
  BookingContent,
  StyledBooking,
  StyledBookingContainer,
  StyledLogo,
} from "./styles";

export const Booking = ({ navigate }) => {
  const { search } = useLocation();
  const {
    setAdultTickets,
    setChildTickets,
    setStartDate,
    isLoading,
    setPriceOption,
    setActiveProduct,
    setFormData,
    setDrinks,
    setHotel,
    isDuende,
  } = useContext(BookingContext);
  const [getCartDetails, { data: cartDetails, isLoading: apiLoader }] =
    bookingApi.endpoints.getCartDetails.useLazyQuery();

  useEffect(() => {
    if (cartDetails) {
      setStartDate(cartDetails.activeProduct.date);
      setAdultTickets(cartDetails.adultTickets);
      setChildTickets(cartDetails.childTickets);
      setPriceOption(cartDetails.priceOption);
      setActiveProduct(cartDetails.activeProduct);
      setFormData({
        email: cartDetails.email,
        emailConfirm: cartDetails.email,
        name: cartDetails.name,
        phone: cartDetails.phone,
        nationality: cartDetails.nationality,
        comments: cartDetails.comments,
        termsConditions: true,
        wheelchair: false,
        vegan: false,
      });
      if (cartDetails.hotel) {
        setHotel(cartDetails.hotel);
      }

      if (Object.keys(cartDetails.drinks).length > 0) {
        const cartDrinks = [];
        Object.keys(cartDetails.drinks).forEach((drink) => {
          cartDrinks.push({ [drink]: cartDetails.drinks[drink] });
        });
        setDrinks(cartDrinks);
      }
    }
  }, [cartDetails]);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(search));
    if (params.cartId) {
      getCartDetails({ id: params.cartId });
      return;
    }
    if (Object.keys(params).length !== 0) {
      if (
        params.date &&
        params.adults &&
        params.child &&
        typeof Number(params.adults) === "number" &&
        typeof Number(params.child) === "number"
      ) {
        setStartDate(params.date);
        setAdultTickets(Number(params.adults));
        setChildTickets(Number(params.child));
        navigate({
          pathname: `/${STEP_CALENDAR}`,
          search,
        });
      }
      if (params.source) {
        let source = params.source;
        if (source[0] === "{" && source[source.length - 1] === "}") {
          source = source.slice(1, -1);
        }
        setHotel(source);
      }
    }

    navigate({
      pathname: "/",
      search,
    });
  }, []);

  return (
    <StyledBooking>
      <StyledBookingContainer>
        <Header backgroundImage="/images/dancers.png">
          <>
            <StyledLogo
              isDuende={isDuende}
              src={`/images/logo-${isDuende ? "duende.png" : "tablao.svg"}`}
              alt="logo"
            />
            <PageHeadline>
              <FormattedMessage
                defaultMessage="Book Flamenco show in Barcelona"
                id="Cj9B8x"
              />
            </PageHeadline>
            <LangSwitcher />
          </>
        </Header>
        <BookingContent direction="column" alignItems="flex-start">
          {(isLoading || apiLoader) && <Loader />}
          <Outlet />
        </BookingContent>
      </StyledBookingContainer>
      <Footer />
    </StyledBooking>
  );
};
