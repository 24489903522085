import { ActiveLang, StyledLangSwitcher } from "./styles";
import { LOCALES } from "../../i18n/locales";
import { useContext } from "react";
import { LanguageContext } from "../../providers/LangProvider";

export const LangSwitcher = () => {
  const { locale, selectLanguage } = useContext(LanguageContext);

  return (
    <StyledLangSwitcher>
      <ActiveLang>
        {locale} <img src="/icons/chevronIcon.svg" />
      </ActiveLang>
      <ul>
        {Object.values(LOCALES).map((loc) => {
          if (loc === locale) return null;
          return (
            <li onClick={() => selectLanguage(loc)} key={loc}>
              {loc}
            </li>
          );
        })}
      </ul>
    </StyledLangSwitcher>
  );
};
