import { FlexContent } from "../../FlexContent/FlexContent";
import {
  SmallHeadline,
  BigHeadline,
  BigLabel,
  MediumParagraph,
} from "../../typography.styles";
import { TimeTile } from "../../dayEvent";
import {
  BookingElement,
  RadioButton,
  RadioButtonLabel,
  RadioButtonWrapper,
} from "./styles";
import { content } from "../../../i18n/content";
import { PromoLabel } from "../../promoLabel";
import { colors } from "theme/colors";
import { Spacer } from "../../spacer";
import { BookingContext } from "../../../providers/BookingProvider";
import { useContext } from "react";
import "react-calendar/dist/Calendar.css";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";
import useScreenSize from "hooks/screenSize";
import {
  CalendarEvents,
  DesktopRadioWrapper,
  ElementWrapper,
  EventDetails,
  ShowPrice,
  ShowTitle,
} from "./Calendar.styles";

export const EventSelectionDuende = ({
  calendar,
  events,
  setEvents,
  activeDate,
}) => {
  const { isMobile } = useScreenSize();
  const { setActiveProduct, setPriceOption, priceOption, isDuende } =
    useContext(BookingContext);

  const { locale } = useIntl();

  return (
    <>
      <Spacer mt={{ xs: 16, md: 32 }} />
      <BigLabel fontWeight={700}>
        <FormattedMessage defaultMessage="Main show time" id="09Rwi+" />
      </BigLabel>
      <Spacer mt={{ xs: 12 }} />
      <FlexContent fullWidth>
        {Object.keys(calendar[activeDate])
          .sort((a, b) => (a < b ? -1 : 1))
          .map((time) => (
            <TimeTile
              onClick={() => [
                setEvents(calendar[activeDate][time]),
                setActiveProduct(calendar[activeDate][time][0]),
              ]}
              isActive={time === events[0].showTime}
              time={time}
              hasWarning={calendar[activeDate][time][0].hasSecondLocation}
            />
          ))}
      </FlexContent>
      <Spacer mt={{ xs: 32, md: 40 }} />
      <BookingElement>
        <BigHeadline isSerifFont={true} style={{ textTransform: "capitalize" }}>
          {dayjs(activeDate).format("dddd DD MMMM YYYY ")}
        </BigHeadline>
        <BigLabel fontWeight={700}>
          {content[locale][events[0].eventType]}
        </BigLabel>

        <Spacer mt={{ xs: 8 }} />

        <Spacer mt={{ xs: 16 }} />

        <CalendarEvents>
          {events.length > 0 &&
            events[0].prices.map((price, index) => (
              <BookingElement
                onClick={() => [
                  setActiveProduct(events[0]),
                  setPriceOption(price),
                ]}
                style={{
                  borderColor:
                    priceOption && price.priceId === priceOption.priceId
                      ? colors.yellow
                      : colors.gray20,
                }}
              >
                <EventDetails>
                  {isMobile && (
                    <FlexContent fullWidth>
                      {index === 0 && (
                        <>
                          <PromoLabel>
                            <FormattedMessage
                              defaultMessage="Best experience"
                              id="R+N+r0"
                            />
                          </PromoLabel>
                        </>
                      )}
                    </FlexContent>
                  )}
                  {!isMobile && (
                    <>
                      <FlexContent
                        fullWidth
                        justify={index === 0 ? "space-between" : "flex-end"}
                        alignItems="flex-start"
                      >
                        {isDuende && index === 0 && (
                          <>
                            <PromoLabel>
                              <FormattedMessage
                                defaultMessage="Best experience"
                                id="R+N+r0"
                              />
                            </PromoLabel>
                            <Spacer mt={{ xs: 12 }} />
                          </>
                        )}
                        <DesktopRadioWrapper>
                          <RadioButton
                            type="radio"
                            name="radio"
                            value={price.priceId}
                            checked={price.priceId === priceOption?.priceId}
                            onChange={() => [
                              setActiveProduct(events[0]),
                              setPriceOption(price),
                            ]}
                          />
                          <RadioButtonLabel />
                        </DesktopRadioWrapper>
                      </FlexContent>
                      <Spacer mt={{ xs: 16 }} />
                    </>
                  )}

                  <ShowTitle direction="column">
                    <Spacer mt={{ xs: 12 }} />
                    <SmallHeadline isUppercase>
                      {isDuende
                        ? `${content[locale][price.priceName.split(" ")[0]]} ${
                            price.priceName.split(" ")[1]
                          }`
                        : `${content[locale][events[0].eventType]}`}
                    </SmallHeadline>

                    <MediumParagraph>
                      {content[locale][price.priceName]}
                    </MediumParagraph>
                    {!isDuende && (
                      <>
                        {events[0].eventType === "show_and_tapas" && (
                          <MediumParagraph color={colors.gray60}>
                            <FormattedMessage
                              defaultMessage="Tapas starts at "
                              id="XHVnem"
                            />{" "}
                            {dayjs(events[0].date).format("HH:mm")}
                          </MediumParagraph>
                        )}
                        {events[0].eventType === "show_and_dinner" && (
                          <MediumParagraph color={colors.gray60}>
                            <FormattedMessage
                              defaultMessage="Dinner starts at "
                              id="ScMNHi"
                            />{" "}
                            {dayjs(events[0].date).format("HH:mm")}
                          </MediumParagraph>
                        )}
                        <MediumParagraph color={colors.gray60}>
                          <FormattedMessage
                            defaultMessage="Show starts at "
                            id="NBLblo"
                          />{" "}
                          {events[0].showTime}
                        </MediumParagraph>
                      </>
                    )}
                  </ShowTitle>
                  <ElementWrapper>
                    <ShowPrice direction="column">
                      <BigLabel fontWeight={700}>{price.price} euro</BigLabel>
                    </ShowPrice>
                    {isMobile && (
                      <RadioButtonWrapper>
                        <RadioButton
                          type="radio"
                          name="radio"
                          value={price.priceId}
                          checked={price.priceId === priceOption?.priceId}
                          onChange={() => [
                            setActiveProduct(events[0]),
                            setPriceOption(price),
                          ]}
                        />
                        <RadioButtonLabel />
                      </RadioButtonWrapper>
                    )}
                  </ElementWrapper>
                </EventDetails>
              </BookingElement>
            ))}
        </CalendarEvents>
      </BookingElement>
    </>
  );
};
