import { LanguageContext } from "providers/LangProvider";
import { useContext } from "react";
import { ContentEn } from "./content_en";
import { ContentEs } from "./content_es";
import { ContentCa } from "./content_ca";
import { StyledTextPage } from "./styles";
import { BookingContent, StyledBooking } from "../Booking/styles";
import { PageHeadline } from "components/typography.styles";
import { LangSwitcher } from "components/languageSwitcher";
import { Header } from "components/Header/Header";
import { LegalsFooter } from "components/legalsFooter";
import { BookingContext } from "providers/BookingProvider";

export const TermsConditions = () => {
  const { locale } = useContext(LanguageContext);
  const { isDuende } = useContext(BookingContext);

  const renderContent = () => {
    if (locale === "es") {
      return <ContentEs />;
    } else if (locale === "ca") {
      return <ContentCa />;
    }

    return <ContentEn />;
  };
  return (
    <StyledBooking>
      <Header backgroundImage="/images/dancers.png">
        <>
          <PageHeadline></PageHeadline>
          <LangSwitcher />
        </>
      </Header>
      <BookingContent direction="column" alignItems="flex-start">
        <StyledTextPage>{renderContent()}</StyledTextPage>
      </BookingContent>
      {!isDuende && <LegalsFooter />}
    </StyledBooking>
  );
};
