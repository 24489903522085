import { ReactElement } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "../redux/store";
import { GlobalStyle } from "../styles/global";
import { Theme } from "../theme";
import BookingProvider from "./BookingProvider";
import LangProvider from "./LangProvider";

export const AppProviders = ({ children }: { children: ReactElement }) => {
  return (
    <Router>
      <LangProvider>
        <Provider store={store}>
          <BookingProvider>
            <Theme>
              {children}
              <GlobalStyle />
            </Theme>
          </BookingProvider>
        </Provider>
      </LangProvider>
    </Router>
  );
};
