import styled from "styled-components";

export const StyledTextPage = styled.div`
  color: black;
  max-width: 750px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) => theme.fonts.serif};
    color: ${({ theme }) => theme.colors.yellow};
    margin: 1rem 0;
  }

  p {
    line-height: 1.4;
    margin: 1rem 0;
    font-size: 16px;

    ${({ theme }) => theme.breakpoints.up("lg")} {
      font-size: 18px;
    }
  }

  b {
    font-weight: 500;
  }

  img {
    margin: 2rem 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: ${({ theme, color }) => color || theme.colors.yellow};
    }
  }
`;
