export const ContentEn = () => (
  <>
    <h2>Terms and Conditions</h2>
    <div>
      <p>
        In compliance with Law 34/2002 on Information Society Services and
        Electronic Commerce (LSSICE), we inform you that{" "}
        <a href="https://tablaocordobes.es">https://tablaocordobes.es</a> is an
        Internet domain and registered trademark by Cadame, S.L. (hereinafter,
        Tablao Flamenco Cordobes), C.I.F. B60132263, with registered office at
        La Rambla 35 in Barcelona and registered in the Barcelona Mercantile
        Registry, Folio 182, Volume 24966, inc 1st Page B-81666 C. Likewise
        TABLAO FLAMENCO CORDOBES.
      </p>
      <p>
        General contract conditions between Tablao Flamenco Cordobes and the
        user:
      </p>
      <ol>
        <li>
          <p>
            The user declares that he or she is of legal age (over 18 years of
            age) and has the necessary legal capacity to contract the services
            offered on the Tablao Flamenco Cordobes website, in accordance with
            the conditions detailed below, which he or she declares to
            understand. and accept.
          </p>
          <p>
            In the case of booking by minors, the authorization of parents or
            guardians is required to be able to enjoy the contracted service.
            The user is solely responsible for the veracity and accuracy of the
            data provided to Tablao Flamenco Cordobes in the reservation
            process.
          </p>
          <p>
            The user agrees to use the services and content on our website in
            accordance with the Law and the general and particular terms and
            conditions of the services offered by Tablao Flamenco Cordobes at
            all times, and must refrain from using them to:
          </p>
          <p>
            (a) Transmit your username and password to unauthorized third
            parties, and must immediately notify Tablao Flamenco Cordobes of
            access by an unauthorized user to said information.
          </p>
          <p>
            (b) Introduce data programs into the network that may cause damage
            to the computer systems of Tablao Flamenco Cordobes, its suppliers
            or third party users of the internal network or this website.
          </p>
          <p>
            (c) Carry out illegal activities, contrary to good faith, customs,
            morality or public order.
          </p>
          <p>
            (d) Carry out activities that constitute a violation of the
            regulation on intellectual and industrial property or any other rule
            of the applicable legal system.
          </p>
          <p>
            (e) Reproduce, duplicate, sell or exploit for commercial purposes
            any content of the website.
          </p>
          <p>
            (f) Disseminate content of a racist, xenophobic, pornographic
            nature, that advocates terrorism and/or that violates human rights.
          </p>
        </li>
        <li>
          <p>
            When registering at Tablao Flamenco Cordobes, the user will choose a
            username and password, which will later be confirmed by email. The
            user must properly safeguard the keys and passwords provided for
            access as a registered user, given that they are personal and
            confidential, with the user being responsible for their use by a
            third person to whom said user has provided them.
          </p>
        </li>
        <li>
          <p>
            The user is responsible for providing their data correctly in the
            contracting processes established on the Tablao Flamenco Cordobes
            website, and must pay special attention to the introduction of data
            related to the delivery address of orders and documents. , as well
            as the names of the third parties with respect to whom they enter
            their data, with prior authorization from said third parties, the
            user must at all times enter all identification data correctly and
            as they appear on the DNI or passport.
          </p>
        </li>
        <li>
          <p>
            Tablao Flamenco Cordobes is the owner of the database generated with
            the personal data provided by users in the process of purchasing
            products through our website. Tablao Flamenco Cordobes is committed
            to complying with its obligation to secrecy of personal data and its
            duty to treat them confidentially, and to this end El Duende by
            Tablao Cordobes will adopt the necessary measures to prevent its
            alteration, loss, treatment or unauthorized access, taking into
            account at all times the state of technology in accordance with the
            provisions of current legislation in Spain regarding the protection
            of personal data.
          </p>
          <p>
            The user accepts that the personal data provided in the purchasing
            process of our products be incorporated into an automated file,
            whose ownership corresponds to Tablao Flamenco Cordobes, in order to
            adequately provide the requested service, comply with billing
            requirements. , accounting and auditing of the company, perform
            statistical analysis, verify credit cards and other payment cards,
            as well as carry out satisfaction surveys and commercial
            communications by email about other products that are marketed
            through the El Duende by website. Tablao Cordobes and that Tablao
            Flamenco Cordobes considers that they could be of interest to the
            user, as they are similar to those contracted. If the user does not
            wish to receive commercial communications by electronic means, they
            can contact El Duende by Tablao Cordobes at any time by sending an
            email to the following address{" "}
            <a href="mailto:tablao@tablaocordobes.com" target="_self">
              tablao@tablaocordobes.com
            </a>
            . The user may also exercise their rights of access, rectification,
            cancellation and opposition by written communication, attaching a
            photocopy of the DNI or equivalent identification document, to EL
            DUENDE BY TABLAO CORDOBES, S.L. at La Rambla 33, 08002 (Barcelona).
          </p>
          <p>
            The document in which the contract related to the contracted product
            is formalized will be filed in a database accessible only by EL
            DUENDE BY TABLAO CORDOBES, S.L. as responsible for it. The client
            may identify and correct errors suffered when entering data by
            notifying Tablao Flamenco Cordobes, using the procedure referred to
            previously in this clause.
          </p>
          <p>
            The user will be responsible, in any case, for the veracity of the
            data provided and will be responsible for communicating to El Duende
            by Tablao Cordobes any modification thereto, El Duende by Tablao
            Cordobes reserving the right to exclude from the registered services
            any user who has provided false data, without prejudice to other
            actions that may be applicable by law.
          </p>
        </li>
        <li>
          <p>
            All contents, brands, logos, drawings, etc. that appear on the El
            Duende by Tablao Cordobes websites, are protected by intellectual
            and industrial property rights that are expressly reserved by El
            Duende by Tablao Cordobes or, where applicable, by the people or
            companies that appear as authors or owners. of the rights. Violation
            of the above rights will be prosecuted in accordance with current
            legislation.
          </p>
          <p>
            Therefore, the reproduction, exploitation, alteration, distribution
            or public communication by any title or means of all the contents of
            the Tablao Flamenco Cordobes web pages for uses other than
            legitimate information or contracting by users is prohibited. of the
            services offered. In any case, the prior written consent of El
            Duende by Tablao Cordobes will be necessary.
          </p>
        </li>
        <li>
          <p>
            The links or links with other web pages that appear on the pages of
            El Duende by Tablao Cordobes are offered for purely informative
            purposes to the user, so Tablao Flamenco Cordobes is not responsible
            for the products, services or contents that are offered or provided
            on landing pages located on another domain.
          </p>
        </li>
        <li>
          <p>
            Cookies: At Tablao Flamenco Cordobes, through the use of cookies, it
            collects and uses information from users to analyze their behavior
            when browsing said website and in this way provide its services more
            efficiently and introduce improvements.
          </p>
          <p>
            A cookie is a small data file that is transferred to your computer's
            hard drive. Tablao Flamenco Cordobes uses cookie technology to
            collect additional data about the use of the website. Browsing the
            El Duende by Tablao Cordobes web pages implies consenting to their
            installation (except to the extent that the user has modified the
            browser settings to reject cookies).
          </p>
          <p>
            Links: In order to provide more appropriate advertising to users and
            improve offers, Tablao Flamenco Cordobes may retain information
            about how the user interacts with links that appear on the website
            or in notifications, redirecting clicks or otherwise. media.
          </p>
        </li>
        <li>
          <p>
            If any clause included in these general conditions is declared,
            totally or partially, null or ineffective, such nullity or
            ineffectiveness will affect only said provision or the part thereof
            that is null or ineffective, the general conditions subsisting in
            everything else, such provision, or the part thereof that is
            affected, being considered not in place.
          </p>
        </li>
        <li>
          <p>
            The user accepts that the legislation applicable to the operation of
            this service is Spanish. The Judges and Courts of the consumer's
            domicile will be competent to hear any discrepancies arising from
            the interpretation or application of this clause.
          </p>
        </li>
        <li>
          <p>
            The final prices indicated on the screen include VAT. and any other
            taxes or fees that may be applicable, at the rate in force at the
            time of contracting.
          </p>
        </li>
        <li>
          <p>
            All reservations made through the{" "}
            <a href="http://elduendebarcelona.com">elduendebarcelona.com</a>{" "}
            website may be canceled during the following 14 business days from
            the date of purchase as specified in current legislation Revised
            Text of the General Law for the Defense of Consumers and Users and
            other complementary laws, approved by RD 1/2007. The refund of the
            corresponding amount will be paid to the client within 30 days
            following the confirmation of cancellation of the reservation using
            the same payment method used by the client. Cancellations or
            modifications will not be accepted under any circumstances if the
            cancellation or modification request has been received by EL DUENDE
            BY TABLAO CORDOBES, S.L. less than 48 hours before service time.
          </p>
        </li>
        <li>
          <p>
            Promotional Policy: TABLAO Flamenco CORDOBES, S.L regularly carries
            out promotions that affect the retail price, which can be modified
            and/or canceled by the company at any time. Any promotion carried
            out will not be cumulative with other promotions and/or Special
            Rates in force. Products in Promotion, New Year's Eve Galas, Palau,
            and/or Roulette Product cannot be canceled and will not give the
            right to a refund of the purchase amount, given their promotional
            nature.
          </p>
        </li>
        <li>
          <p>
            For any suggestion, query, complaint or claim, the user must contact
            CADAME, S.L. to the address La Rambla 35 08002 Barcelona,
            &ZeroWidthSpace;&ZeroWidthSpace;or by telephone 933175711 or by
            electronic communication to{" "}
            <a href="mailto:tablao@tablaocordobes.com" target="_self">
              tablao@tablaocordobes.com
            </a>
            .
          </p>
        </li>
      </ol>
    </div>
  </>
);
