import { Counter } from "../counter";
import { StyledCounter } from "./styles";

type CounterProps = {
  onClick: (tickets: number) => void;
  value: number;
  children: React.ReactNode;
  maxValue?: number;
};

export const CounterComponent = ({
  onClick,
  value,
  children,
  maxValue = 50,
}: CounterProps) => (
  <StyledCounter justify="space-between" fullWidth>
    {children}
    <Counter onClick={onClick} value={value} maxValue={maxValue} />
  </StyledCounter>
);
