import { FlexWrapper } from "./FlexContent.styles";
import { FlexContentProps } from "./FlexContent.types";

export const FlexContent = ({
  children,
  justify,
  alignItems,
  direction,
  mobileDirection,
  className,
  onClick,
  fullWidth,
  wrap,
  padding,
}: FlexContentProps) => {
  return (
    <FlexWrapper
      justify={justify}
      alignItems={alignItems}
      mobileDirection={mobileDirection}
      direction={direction}
      className={className}
      fullWidth={fullWidth}
      wrap={wrap}
      onClick={onClick}
      padding={padding}
    >
      {children}
    </FlexWrapper>
  );
};
