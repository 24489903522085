import styled from "styled-components";

export const StyledCalendarCarousel = styled.div`
  width: 100%;

  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.colors.yellow};
  }
`;
