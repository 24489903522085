export const showAndDinner = "show_and_dinner";
export const showAndTapas = "show_and_tapas";
export const showAndDrink = "show_and_drink";
export const onlyShow = "show";
export const content = {
  en: {
    only_show: "Only show",
    show_and_drink: "Show and drink",
    show_and_dinner: "Dinner and show",
    show_and_tapas: "Tapas and show",
    zone: "Zone",
    new_year: "Gala dinner and Flamenco Party",
    "zone A": "front and box seats",
    "zone B": "first and middle side rows",
    "zone C": "end rows",
    descriptions: {
      show_and_dinner:
        "Experience an unforgettable Show & Culinary Tour, offering over 40 delicious menu options, including vegan, vegetarian, halal, and gluten-free dishes. Enjoy unlimited beer, wine, sangria, and soft drinks throughout dinner, with a complimentary glass of cava to toast during the show.",
      show_and_drink:
        "Best Flamenco Show in Spain with one drink included during the show",
      show_and_tapas:
        "Enjoy a selection of 10 seasonal tapas, featuring traditional favorites like Iberian ham, patatas bravas, Manchego cheese, and paella. Vegan options include wheat salad, hummus, couscous salad, falafel pintxo, and vegetable paella. One drink is included.",
    },
  },
  fr: {
    only_show: "Seulement le spectacle",
    show_and_drink: "Spectacle et boisson",
    show_and_dinner: "Dîner et spectacle",
    show_and_tapas: "Tapas et spectacle",
    zone: "Zone",
    new_year: "Dîner de gala et fête flamenco",
    "zone A": "premières places et loges",
    "zone B": "premières rangées et rangées latérales du milieu",
    "zone C": "dernières rangées",
    descriptions: {
      show_and_dinner:
        "Vivez une expérience inoubliable avec notre Spectacle et Tour Culinaire, proposant plus de 40 options délicieuses, y compris des plats végétaliens, végétariens, halal et sans gluten. Profitez de bière, vin, sangria et boissons sans alcool à volonté pendant le dîner, avec une coupe de cava offerte pendant le spectacle.",
      show_and_drink:
        "Le meilleur spectacle de flamenco en Espagne avec une boisson incluse pendant le spectacle.",
      show_and_tapas:
        "Profitez d’une sélection de 10 tapas de saison, avec des classiques comme le jambon ibérique, patatas bravas, fromage Manchego et paella. Les options véganes incluent salade de blé, houmous, salade de couscous, pintxo de falafel et paella de légumes. Une boisson est incluse.",
    },
  },
  es: {
    only_show: "Solo espectáculo",
    show_and_drink: "Espectáculo y bebida",
    show_and_dinner: "Cena y espectáculo",
    show_and_tapas: "Degustación de tapas y espectáculo",
    new_year: "Cena de Gala Fin de Año y Fiesta Flamenca",
    zone: "Zona",
    "zone A": "asientos frontales y palco",
    "zone B": "primeras filas e intermedias",
    "zone C": "filas finales",
    descriptions: {
      show_and_dinner:
        "Vive una experiencia inolvidable con nuestro Show y Tour Gastronómico, que ofrece más de 40 opciones deliciosas en el menú, incluyendo platos veganos, vegetarianos, halal y sin gluten. Disfruta de cerveza, vino, sangría y refrescos ilimitados durante la cena, con una copa de cava de cortesía durante el espectáculo.",
      show_and_drink:
        "El mejor espectáculo de flamenco en España con una bebida incluida durante el show.",
      show_and_tapas:
        "Disfruta de una selección de 10 tapas de temporada, con clásicos como jamón ibérico, patatas bravas, queso manchego y paella. Las opciones veganas incluyen ensalada de trigo, hummus, ensalada de cuscús, pintxo de falafel y paella de verduras. Incluye una bebida.",
    },
  },
  ca: {
    only_show: "Només espectacle",
    show_and_drink: "Espectacle i copa",
    show_and_dinner: "Cena i espectacle",
    show_and_tapas: "Degustació de tapas i espectacle",
    zone: "Zona",
    new_year: "Sopar d Any nou i Festa Flamenca",
    "zone A": "seients frontals i llotja",
    "zone B": "primeres files e intermitges",
    "zone C": "files finals",
    descriptions: {
      show_and_dinner:
        "Gaudeix d’una experiència inoblidable amb el nostre Espectacle i Tour Gastronòmic, que ofereix més de 40 opcions delicioses al menú, incloent plats vegans, vegetarians, halal i sense gluten. Gaudeix de cervesa, vi, sangria i refrescos il·limitats durant el sopar, amb una copa de cava de cortesia durant l'espectacle.",
      show_and_drink:
        "El millor espectacle de flamenc a Espanya amb una beguda inclosa durant l’espectacle.",
      show_and_tapas:
        "Gaudeix d’una selecció de 10 tapes de temporada, amb clàssics com pernil ibèric, patates braves, formatge manxec i paella. Les opcions veganes inclouen amanida de blat, hummus, amanida de cuscús, pintxo de falàfel i paella de verdures. Inclou una beguda.",
    },
  },
  it: {
    only_show: "Solo spettacolo",
    show_and_drink: "Spettacolo e bevanda",
    show_and_dinner: "Cena e spettacolo",
    show_and_tapas: "Tapas e spettacolo",
    zone: "Zona",
    new_year: "Cena di gala e Festa Flamenca",
    "zone A": "posti frontali e palchi",
    "zone B": "prime file e file laterali centrali",
    "zone C": "ultime file",
    descriptions: {
      show_and_dinner:
        "Vivi un indimenticabile spettacolo e tour culinario, con oltre 40 opzioni di menu deliziosi, tra cui piatti vegani, vegetariani, halal e senza glutine. Goditi birra, vino, sangria e bevande analcoliche illimitati durante la cena, con un bicchiere di cava offerto per brindare durante lo spettacolo.",
      show_and_drink:
        "Il miglior spettacolo di flamenco in Spagna con una bevanda inclusa durante lo spettacolo.",
      show_and_tapas:
        "Goditi una selezione di 10 tapas stagionali, con i classici tradizionali come prosciutto iberico, patatas bravas, formaggio Manchego e paella. Le opzioni vegane includono insalata di grano, hummus, insalata di cous cous, pintxo di falafel e paella di verdure. Una bevanda è inclusa.",
    },
  },
  ja: {
    only_show: "ショーのみ",
    show_and_drink: "ショーとドリンク",
    show_and_dinner: "ディナーとショー",
    show_and_tapas: "タパスとショー",
    zone: "ゾーン",
    new_year: "ガラディナーとフラメンコパーティー",
    "zone A": "前列とボックス席",
    "zone B": "前列と中央側列",
    "zone C": "後列",
    descriptions: {
      show_and_dinner:
        "40種類以上の美味しいメニューから選べる、忘れられないショーと料理ツアーを体験してください。ビーガン、ベジタリアン、ハラール、グルテンフリーの料理もご用意しています。ディナー中はビール、ワイン、サングリア、ソフトドリンクが無制限で楽しめ、ショーの間には乾杯用のカバが1杯無料で提供されます。",
      show_and_drink:
        "スペイン最高のフラメンコショーに、ショー中にドリンク1杯が含まれます。",
      show_and_tapas:
        "イベリコハム、パタタスブラバス、マンチェゴチーズ、パエリアなどの伝統的な人気料理を含む、旬のタパス10種類をお楽しみください。ビーガンオプションには、小麦サラダ、フムス、クスクスサラダ、ファラフェルピンチョ、野菜パエリアがあります。ドリンク1杯が含まれます。",
    },
  },
};
