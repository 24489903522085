import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useRef } from "react";
import { TimeTile } from "../dayEvent";
import { ParsedCalendarType } from "../../utils/parseData";
import useScreenSize from "../../hooks/screenSize";
import { StyledCalendarCarousel } from "./styles";

export const CalendarCarousel = ({
  events,
  setActiveDate,
  activeDate,
}: {
  events: ParsedCalendarType;
  setActiveDate: (date: string) => void;
  activeDate: string;
  startDate?: string;
}) => {
  const { screenSize } = useScreenSize();

  const sliderRef = useRef<Slider>(null);

  const getVisibleDates = () => {
    if (screenSize.width > 950) {
      return 10;
    } else if (screenSize.width > 600) {
      return 7;
    } else if (screenSize.width > 480) {
      return 6;
    } else if (screenSize.width > 360) {
      return 5;
    }

    return 3;
  };

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: getVisibleDates(),
    swipeToSlide: true,
    slidesToScroll: 1,
    centerMode: false,
    focusOnSelect: true,
    centerPadding: "0",
    autoplay: false,
    initialSlide: Object.keys(events).findIndex((date) => date === activeDate),
  };

  useEffect(() => {
    //find index of activeDate in events
    const index = Object.keys(events).findIndex((date) => date === activeDate);
    handleGoToSlide(index);
  }, [activeDate]);

  const handleGoToSlide = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <StyledCalendarCarousel>
      <Slider ref={sliderRef} {...settings}>
        {Object.keys(events)
          .sort((a, b) => (a < b ? -1 : 1))
          .map((date) => {
            return (
              <TimeTile
                key={date}
                isDate
                time={date}
                isActive={date === activeDate}
                onClick={() => setActiveDate(date)}
              />
            );
          })}
      </Slider>
    </StyledCalendarCarousel>
  );
};
