import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentsRedsys } from "../../Payments/PaymentsRedsys";

export const PaymentLink = () => {
  const navigate = useNavigate();
  const submitRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const merchantParameters = urlParams.get("Ds_MerchantParameters");
    const signature = urlParams.get("Ds_Signature");
    const signatureVersion = urlParams.get("Ds_SignatureVersion");

    if (merchantParameters && signature && signatureVersion) {
      if (submitRef.current) {
        submitRef.current.click();
      }
    } else {
      navigate("/payment-error");
    }
  }, [navigate]);

  return (
    <PaymentsRedsys
      ref={submitRef}
      signature={
        new URLSearchParams(window.location.search).get("Ds_Signature") || ""
      }
      signatureVersion={
        new URLSearchParams(window.location.search).get(
          "Ds_SignatureVersion",
        ) || ""
      }
      merchantParameters={
        new URLSearchParams(window.location.search).get(
          "Ds_MerchantParameters",
        ) || ""
      }
    />
  );
};
