import { FlexContent } from "components/FlexContent/FlexContent";
import { styled } from "styled-components";

export const StyledLegals = styled(FlexContent)`
  max-width: 750px;
  margin: 0 auto;
  padding: 10px 0;
  font-size: 12px;
  color: white;

  a {
    color: white;
    text-decoration: underline;
    margin: 0 10px;
  }
`;
