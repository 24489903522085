import styled from "styled-components";

export const ButtonStyled = styled.button<{
  variant?: "secondary" | "default";
  size?: "small" | "default";
}>`
  all: unset;
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  box-sizing: border-box;
  justify-content: center;
  text-transform: uppercase;

  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  ${({ size }) => {
    switch (size) {
      case "small":
        return `
              padding: 8px 15px;
              font-size: 12px;
            `;
      default:
        return `
              padding: 14px 24px;
              font-size: 16px;

            `;
    }
  }}

  ${({ variant, theme }) => {
    switch (variant) {
      case "secondary":
        return `
            background: ${theme.colors.gray100};
            color: ${theme.colors.white};
            border: 1px solid ${theme.colors.white};
            `;
      default:
        return `
            background: ${theme.colors.yellow};
            color: ${theme.colors.white};
            border: 1px solid ${theme.colors.yellow};
            `;
    }
  }}

  &:disabled {
    filter: brightness(0.5);
  }
`;
