import { BigHeadline, BigParagraph } from "../typography.styles";
import { FormattedMessage, useIntl } from "react-intl";
import { Spacer } from "../spacer";
import { FlexContent } from "../FlexContent/FlexContent";
import { Button } from "../button/button";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PaymentError = () => {
  const navigate = useNavigate();
  const { locale } = useIntl();
  return (
    <FlexContent
      direction="column"
      alignItems="center"
      justify="center"
      fullWidth
    >
      <BigHeadline>
        <FormattedMessage defaultMessage="Error" id="KN7zKn" />
      </BigHeadline>
      <Spacer mt={{ xs: 16, md: 24 }} />
      <BigParagraph textAlign="center">
        Your payment was unsuccesful. Reservation did not go through. Please try
        again.
      </BigParagraph>
      <Spacer mt={{ xs: 16, md: 24 }} />
      <Button onClick={() => navigate(`/${locale === "en" ? "" : locale}`)}>
        <FormattedMessage
          defaultMessage="Go back to booking page"
          id="c3TpDY"
        />
      </Button>
    </FlexContent>
  );
};
