import styled from "styled-components";
import { FlexContent } from "../../FlexContent/FlexContent";
import { BookingElement } from "./styles";

export const SummaryInfo = styled.div``;
export const SummaryBill = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
    align-self: flex-end;
  }
`;

export const StyledSummaryBox = styled(FlexContent)`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  ${SummaryInfo} {
    width: 100%;
  }

  ${BookingElement} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;

    ${SummaryInfo} {
      width: 50%;
    }

    ${BookingElement} {
      width: calc(50% - 10px);
    }
  }
`;
