export const textSizes = {
  compact: "0.625rem",
  smaller: "0.75rem",
  small: "0.875rem",
  normal: "1rem",
  large: "1.25rem",
  larger: "1.5rem",
  veryLarge: "2rem",
  extraLarge: "2.625rem",
};

export type ThemeTextSizes = typeof textSizes;
