import { WarningIcon } from "components/icons/WarningIcon";
import { WarningMessage } from "components/views/Booking/styles";
import { BannerHeadline, BannerHighlight, BannerText } from "./styles";
import { Button } from "components/button/button";
import { FormattedMessage, useIntl } from "react-intl";
import { EL_DUENDE_DOMAIN } from "env";
import { BookingContext } from "providers/BookingProvider";
import { useContext } from "react";
import { FlexContent } from "components/FlexContent/FlexContent";
import { Spacer } from "components/spacer";

export const SoldoutBanner = ({ activeDate }) => {
  const { adultTickets, childTickets } = useContext(BookingContext);
  const { formatMessage } = useIntl();
  return (
    <WarningMessage>
      <FlexContent
        justify="center"
        direction="column"
        alignItems="center"
        fullWidth
      >
        <FlexContent justify="center" fullWidth>
          <WarningIcon />
          <BannerHeadline>
            {formatMessage({
              defaultMessage: "Sold out at your preferred time?",
              id: "XW16e4",
            })}
          </BannerHeadline>
        </FlexContent>
        <Spacer mt={{ xs: 12, md: 15 }} />
        <BannerText>
          {formatMessage({
            defaultMessage: "As an alternative, we recommend our new venue:",
            id: "8+z1O1",
          })}
        </BannerText>
        <BannerHighlight>
          {formatMessage({
            defaultMessage: "El Duende by Tablao Cordobes",
            id: "aS/erm",
          })}
        </BannerHighlight>
        <BannerText>
          {formatMessage({
            defaultMessage: "Located at La Rambla 33, just a few steps away.",
            id: "p5xrrp",
          })}
        </BannerText>
        <Spacer mt={{ xs: 10 }} />
        <BannerText>
          {formatMessage({
            defaultMessage: "We hope you enjoy your experience at our tablao.",
            id: "W7hsFj",
          })}
        </BannerText>
        <Spacer mt={{ xs: 12, md: 15 }} />
        <a
          href={`https://${EL_DUENDE_DOMAIN}?date=${activeDate}&adults=${adultTickets}&child=${childTickets}`}
          target="_blank"
        >
          <Button size="small">
            <FormattedMessage
              defaultMessage="Take me to El Duende"
              id="00m+Rf"
            />
          </Button>
        </a>
      </FlexContent>
    </WarningMessage>
  );
};
