import { useState } from "react";
import { StyledCounter } from "./styles";
import { PlusIcon } from "../icons/PlusIcon";
import { MinusIcon } from "../icons/MinusIcon";

export const Counter = ({ value, onClick, maxValue }) => {
  const [count, setCount] = useState<number>(value);

  const increment = () => {
    setCount(count + 1);
    onClick(count + 1);
  };

  const decrement = () => {
    setCount(count - 1);
    onClick(count - 1);
  };

  const manualChange = (event) => {
    let val = Math.round(Number(event.target.value));
    if (val > maxValue) {
      val = maxValue;
    } else if (val < 0) {
      val = 0;
    }

    setCount(val);
    onClick(val);
  };

  return (
    <StyledCounter>
      <button disabled={count === 0} onClick={decrement}>
        <MinusIcon />
      </button>
      <input
        min={0}
        max={maxValue}
        type="number"
        value={count}
        onChange={manualChange}
      />
      <button disabled={count === 50} onClick={increment}>
        <PlusIcon />
      </button>
    </StyledCounter>
  );
};
