import styled from "styled-components";

import { FlexWrapperProps } from "./FlexContent.types";

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  justify-content: ${({ justify }) => justify || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-direction: ${({ mobileDirection, direction }) =>
    mobileDirection || direction || "row"};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "no-wrap")};
  position: relative;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "unset")};
  padding: ${({ padding }) => padding || "0"};

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: ${({ direction }) => direction || "row"};
  }
`;
