export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect y="7" width="16" height="2" fill="#EAA945" />
    <rect
      x="9"
      width="16"
      height="2"
      transform="rotate(90 9 0)"
      fill="#EAA945"
    />
  </svg>
);
