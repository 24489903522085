import styled from "styled-components";
import { FlexContent } from "../FlexContent/FlexContent";
import { ButtonStyled } from "../button/button.styled";

export const StyledFooter = styled(FlexContent)<{ flexEnd: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.gray100};
  justify-content: ${({ flexEnd }) => (flexEnd ? "flex-end" : "space-between")};
  max-width: 960px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 15px;

  ${ButtonStyled} {
    &:nth-of-type(2) {
      margin-left: 16px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: relative;
    padding-bottom: 10px;

    ${ButtonStyled} {
      &:nth-of-type(2) {
        margin-left: 24px;
      }
    }
  }
`;
