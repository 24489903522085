import * as React from "react";
import { useState } from "react";
import {
  PriceType,
  SendCartDetailsRequest,
  SingleShowType,
} from "../api/booking";
import { BookingContextType, FormType } from "./BookingProvider.types";
import { useSendCartDetailsMutation } from "../api/booking";
import {
  BOOKING_STEPS,
  STEP_CALENDAR,
  STEP_FORM,
  STEP_SEATS,
  STEP_SUMMARY,
} from "../consts";
import TagManager from "react-gtm-module";
import dayjs from "dayjs";
import { EL_DUENDE_DOMAIN } from "../env";
import { content } from "i18n/content";
import { useIntl } from "react-intl";

export const defaultForm = {
  email: "",
  emailConfirm: "",
  name: "",
  phone: "",
  nationality: "",
  comments: "",
  wheelchair: false,
  vegan: false,
  paymentMethod: "",
  termsConditions: false,
  isCompany: false,
};

export const BookingContext = React.createContext<BookingContextType | null>(
  null,
);

const BookingProvider = ({ children }) => {
  const location =
    window.location.hostname === EL_DUENDE_DOMAIN ? "duende" : "tablao";
  const isDuende = location === "duende";
  const [gtmObject, setGtmObject] = useState(null);
  const [hotel, setHotel] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>();
  const [adultTickets, setAdultTickets] = useState<number>(2);
  const [childTickets, setChildTickets] = useState<number>(0);
  const [nextStepEnabled, enableNextStep] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [drinks, setDrinks] = useState<Record<string, number>[]>([
    { unknown: 0 },
  ]);
  const [activeProduct, setActiveProduct] = useState<SingleShowType | null>(
    null,
  );
  const [priceOption, setPriceOption] = useState<PriceType | null>(null);
  const [formData, setFormData] = useState<FormType>(defaultForm);
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { locale } = useIntl();
  const [sendCart] = useSendCartDetailsMutation();

  const sendStepAnalytics = (step: number) => {
    if (BOOKING_STEPS[step] === STEP_CALENDAR) {
      TagManager.dataLayer({
        dataLayer: {
          event: "elementClick",
          event_info: {
            type: "button",
            name: "Booking Next",
            guests: `Adults:${adultTickets}, Children:${childTickets}`,
            children: childTickets ? "yes" : "no",
          },
        },
      });
    }

    if (BOOKING_STEPS[step] === STEP_SEATS) {
      const gtmItems = [
        {
          item_id: activeProduct.eventId,
          item_name: activeProduct.eventType,
          quantity: adultTickets,
          item_brand: "Duende",
          item_category: `Adults:${adultTickets}, Children:${childTickets}`,
          item_category2: "Adults",
          item_category3: dayjs(activeProduct.date).format("YYYY-MM-DD"),
          item_category4: dayjs(activeProduct.date).format("HH:mm"),
        },
      ];

      if (childTickets) {
        gtmItems.push({
          item_id: activeProduct.eventId,
          item_name: activeProduct.eventType,
          quantity: childTickets,
          item_brand: "Duende",
          item_category: `Adults:${adultTickets}, Children:${childTickets}`,
          item_category2: "Children",
          item_category3: dayjs(activeProduct.date).format("YYYY-MM-DD"),
          item_category4: dayjs(activeProduct.date).format("HH:mm"),
        });
      }

      setGtmObject(gtmItems);
      TagManager.dataLayer({
        dataLayer: {
          event: "view_item",
          ecommerce: {
            currency: "EUR",
            items: gtmItems,
          },
        },
      });
    }

    if (BOOKING_STEPS[step] === STEP_FORM) {
      const gtmItems = [
        {
          item_id: activeProduct.eventId,
          item_name: activeProduct.eventType,
          quantity: adultTickets,
          item_brand: "Duende",
          item_category: `Adults:${adultTickets}, Children:${childTickets}`,
          item_category2: "Adults",
          item_category3: dayjs(activeProduct.date).format("YYYY-MM-DD"),
          item_category4: dayjs(activeProduct.date).format("HH:mm"),
        },
      ];

      if (childTickets) {
        gtmItems.push({
          item_id: activeProduct.eventId,
          item_name: activeProduct.eventType,
          quantity: childTickets,
          item_brand: "Duende",
          item_category: `Adults:${adultTickets}, Children:${childTickets}`,
          item_category2: "Children",
          item_category3: dayjs(activeProduct.date).format("YYYY-MM-DD"),
          item_category4: dayjs(activeProduct.date).format("HH:mm"),
        });
      }

      gtmItems[0]["item_variant"] = priceOption.priceName;
      gtmItems[0]["price"] = priceOption.price;

      if (childTickets) {
        gtmItems[1]["item_variant"] = priceOption.priceName;
        gtmItems[1]["price"] = priceOption.price;
      }

      setGtmObject(gtmItems);
      TagManager.dataLayer({
        dataLayer: {
          event: "add_to_cart",
          ecommerce: {
            currency: "EUR",
            value: (adultTickets + childTickets) * priceOption.price,
            items: gtmItems,
          },
        },
      });
    }

    if (BOOKING_STEPS[step] === STEP_SUMMARY) {
      TagManager.dataLayer({
        dataLayer: {
          event: "begin_checkout",
          ecommerce: {
            currency: "EUR",
            value: (adultTickets + childTickets) * priceOption.price,
            items: gtmObject,
          },
        },
      });
    }
  };

  const sendCartInfo = () => {
    const data: SendCartDetailsRequest = {
      eventId: activeProduct.eventId,
      priceId: priceOption.priceId,
      adultTickets,
      childTickets,
      email: formData.email,
      name: formData.name,
      phone: formData.phone,
      nationality: formData.nationality,
      comments: formData.comments,
      wheelchair: formData.wheelchair ? "1" : "0",
      vegan: formData.vegan ? "1" : "0",
      languageCode: locale,
      eventName: content[locale][activeProduct.eventType],
      eventDate: activeProduct.date,
      totalPrice: priceOption.price * (adultTickets + childTickets),
      priceName: priceOption.priceName,
      location: activeProduct.place,
      drinks: Object.assign({}, ...drinks),
    };

    if (hotel) {
      data.hotel = hotel;
    }
    sendCart(data);
  };

  return (
    <BookingContext.Provider
      value={{
        isDuende,
        location,
        sendStepAnalytics,
        activeStep,
        adultTickets,
        childTickets,
        setAdultTickets,
        setChildTickets,
        nextStepEnabled,
        enableNextStep,
        activeProduct,
        setActiveProduct,
        priceOption,
        setPriceOption,
        formData,
        setFormData,
        startDate,
        setStartDate,
        paymentMethod,
        setPaymentMethod,
        isLoading,
        setIsLoading,
        gtmObject,
        setGtmObject,
        setActiveStep,
        drinks,
        setDrinks,
        sendCartInfo,
        hotel,
        setHotel,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
