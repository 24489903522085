import { colors } from "../../../theme";

export const WarningIcon = () => (
  <svg
    fill={colors.yellow}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g>
      <g>
        <g>
          <path d="M86.515,86.432H13.485c-1.328,0-2.324-0.665-2.987-1.66c-0.665-0.995-0.665-2.324,0-3.319l36.515-66.39     c1.328-1.992,4.646-1.992,5.977,0l36.514,66.39c0.662,0.995,0.662,2.324,0,3.319C88.838,85.767,87.844,86.432,86.515,86.432z      M19.129,79.791h61.742L50,23.692L19.129,79.791z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M50,63.195c-1.992,0-3.32-1.329-3.32-3.32V43.277c0-1.992,1.328-3.319,3.32-3.319c1.991,0,3.32,1.327,3.32,3.319v16.597     C53.319,61.866,51.99,63.195,50,63.195z" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="50" cy="69.833" r="3.319" />
        </g>
        <g>
          <path d="M50,74.813c-2.655,0-4.979-2.324-4.979-4.979c0-2.654,2.325-4.979,4.979-4.979s4.979,2.324,4.979,4.979     C54.979,72.488,52.654,74.813,50,74.813z M50,68.174c-0.995,0-1.66,0.665-1.66,1.659c0,0.995,0.665,1.659,1.66,1.659     c0.997,0,1.661-0.664,1.661-1.659C51.66,68.839,50.996,68.174,50,68.174z" />
        </g>
      </g>
    </g>
  </svg>
);
