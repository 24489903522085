import styled from "styled-components";

export const StyledCounter = styled.div`
  display: flex;
  align-items: stretch;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.15));

  input {
    all: unset;
    background: ${({ theme }) => theme.colors.beige};
    line-height: 32px;
    padding: 6px 0;
    font-weight: 700;
    width: 50px;
    text-align: center;
  }

  button {
    border: none;
    background: ${({ theme }) => theme.colors.white};
    padding: 8px;
    color: ${({ theme }) => theme.colors.black};
    line-height: 0;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.up("md")} {
      &:nth-of-type(2) {
        margin-left: -15px;
      }
    }
  }
`;
