import { FlexContent } from "components/FlexContent/FlexContent";
import { CounterComponent } from "components/peopleCounter";
import { Spacer } from "components/spacer";
import { Label, SectionHeadline } from "components/typography.styles";
import {
  BookingHeader,
  BookingElement,
  DrinkCounterWrapper,
  DrinkButton,
} from "components/views/Booking/styles";
import { drinkVariants } from "i18n/drinks";
import { BookingContext } from "providers/BookingProvider";
import { SelectType } from "providers/BookingProvider.types";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { colors } from "theme/colors";

const DrinkCounter = ({
  handleCounterClick,
  drinkName,
  drinkCount,
  maxValue,
  children,
}) => (
  <>
    <DrinkCounterWrapper>
      <CounterComponent
        onClick={(value) => handleCounterClick(drinkName, value)}
        value={drinkCount}
        maxValue={maxValue}
      >
        {children}
      </CounterComponent>
      <Spacer mt={{ xs: 8, md: 16 }} />
    </DrinkCounterWrapper>
  </>
);
export const DrinkSelection = () => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { drinks, setDrinks, adultTickets, childTickets } =
    useContext(BookingContext);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [usedTypes, setUsedTypes] = useState<string[]>([]);
  const handleCounterClick = (drink, value) => {
    const drinksCopy = [...drinks];
    const index = drinks.findIndex((d) => Object.keys(d)[0] === drink);
    drinksCopy[index] = { [drink]: value };
    setDrinks(drinksCopy);
  };

  useEffect(() => {
    let drinksSum = 0;
    drinks.forEach((drink) => {
      drinksSum += Object.values(drink)[0];
    });

    if (!isTouched && drinksSum > 0) {
      setIsTouched(true);
    }
  }, [drinks]);
  useEffect(() => {
    let drinksSum = 0;
    drinks.forEach((drink) => {
      drinksSum += Object.values(drink)[0];
    });
    if (isTouched && drinksSum !== adultTickets + childTickets) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [drinks, adultTickets, childTickets]);

  const parseDrinkOptions = () => {
    const resp = drinkVariants[locale].filter(
      (variant) => !usedTypes.includes(variant.value),
    );

    return resp;
  };

  const { locale } = useIntl();
  return (
    <>
      <Spacer mt={{ xs: 15, md: 40 }} />
      <BookingHeader justify="space-between">
        <SectionHeadline>
          3. <FormattedMessage defaultMessage="Choose Your Drink" id="Hl1ald" />
        </SectionHeadline>
      </BookingHeader>
      <Spacer mt={{ xs: 16, md: 32 }} />
      <BookingElement>
        <FlexContent
          direction="row"
          mobileDirection="column"
          wrap
          justify="flex-start"
          fullWidth
          alignItems="flex-start"
        >
          {drinks.map((drink, index) => (
            <DrinkCounter
              handleCounterClick={handleCounterClick}
              drinkName={Object.keys(drink)[0]}
              drinkCount={Object.values(drink)[0]}
              maxValue={childTickets + adultTickets}
            >
              <Select
                placeholder="Choose drink type"
                className="react-select-containexr"
                options={parseDrinkOptions()}
                classNamePrefix="react-select"
                onChange={(e: SelectType) => {
                  const drinksCopy = [...drinks];
                  drinksCopy[index] = {
                    [e.value]: Object.values(drinksCopy[index])[0],
                  };

                  setUsedTypes([...usedTypes, e.value]);

                  return [setDrinks(drinksCopy)];
                }}
              />
            </DrinkCounter>
          ))}
        </FlexContent>
        <DrinkButton onClick={() => setDrinks([...drinks, { unknown: 0 }])}>
          Add another drink type
        </DrinkButton>
        <Spacer mt={{ xs: 8, md: 16 }} />
        {showWarning && (
          <Label style={{ color: colors.red }}>
            <FormattedMessage
              defaultMessage="Drink is included in the ticket price and its number must equal the number of guests."
              id="6xrx5L"
            />
          </Label>
        )}
      </BookingElement>
    </>
  );
};
