export const countriesAll = [
  {
    value: "AF",
    label_en: "Afghanistan",
    label_fr: "Afghanistan",
    label_es: "Afganistán",
    label_ca: "Afganistan",
    label_it: "Afghanistan",
    label_ja: "アフガニスタン",
  },
  {
    value: "AL",
    label_en: "Albania",
    label_fr: "Albanie",
    label_es: "Albania",
    label_ca: "Albània",
    label_it: "Albania",
    label_ja: "アルバニア",
  },
  {
    value: "DZ",
    label_en: "Algeria",
    label_fr: "Algérie",
    label_es: "Argelia",
    label_ca: "Algèria",
    label_it: "Algeria",
    label_ja: "アルジェリア",
  },
  {
    value: "AS",
    label_en: "American Samoa",
    label_fr: "Samoa américaines",
    label_es: "Samoa Americana",
    label_ca: "Samoa Nord-americana",
    label_it: "Samoa Americane",
    label_ja: "アメリカ領サモア",
  },
  {
    value: "AD",
    label_en: "Andorra",
    label_fr: "Andorre",
    label_es: "Andorra",
    label_ca: "Andorra",
    label_it: "Andorra",
    label_ja: "アンドラ",
  },
  {
    value: "AO",
    label_en: "Angola",
    label_fr: "Angola",
    label_es: "Angola",
    label_ca: "Angola",
    label_it: "Angola",
    label_ja: "アンゴラ",
  },
  {
    value: "AI",
    label_en: "Anguilla",
    label_fr: "Anguilla",
    label_es: "Anguila",
    label_ca: "Anguilla",
    label_it: "Anguilla",
    label_ja: "アンギラ",
  },
  {
    value: "AQ",
    label_en: "Antarctica",
    label_fr: "Antarctique",
    label_es: "Antártida",
    label_ca: "Antàrtida",
    label_it: "Antartide",
    label_ja: "南極大陸",
  },
  {
    value: "AG",
    label_en: "Antigua and Barbuda",
    label_fr: "Antigua-et-Barbuda",
    label_es: "Antigua y Barbuda",
    label_ca: "Antigua i Barbuda",
    label_it: "Antigua e Barbuda",
    label_ja: "アンティグア・バーブーダ",
  },
  {
    value: "AR",
    label_en: "Argentina",
    label_fr: "Argentine",
    label_es: "Argentina",
    label_ca: "Argentina",
    label_it: "Argentina",
    label_ja: "アルゼンチン",
  },
  {
    value: "AM",
    label_en: "Armenia",
    label_fr: "Arménie",
    label_es: "Armenia",
    label_ca: "Armènia",
    label_it: "Armenia",
    label_ja: "アルメニア",
  },
  {
    value: "AW",
    label_en: "Aruba",
    label_fr: "Aruba",
    label_es: "Aruba",
    label_ca: "Aruba",
    label_it: "Aruba",
    label_ja: "アルバ",
  },
  {
    value: "AU",
    label_en: "Australia",
    label_fr: "Australie",
    label_es: "Australia",
    label_ca: "Austràlia",
    label_it: "Australia",
    label_ja: "オーストラリア",
  },
  {
    value: "AT",
    label_en: "Austria",
    label_fr: "Autriche",
    label_es: "Austria",
    label_ca: "Àustria",
    label_it: "Austria",
    label_ja: "オーストリア",
  },
  {
    value: "AZ",
    label_en: "Azerbaijan",
    label_fr: "Azerbaïdjan",
    label_es: "Azerbaiyán",
    label_ca: "Azerbaidjan",
    label_it: "Azerbaigian",
    label_ja: "アゼルバイジャン",
  },
  {
    value: "BS",
    label_en: "Bahamas (the)",
    label_fr: "Bahamas",
    label_es: "Bahamas",
    label_ca: "Bahames",
    label_it: "Bahamas",
    label_ja: "バハマ",
  },
  {
    value: "BH",
    label_en: "Bahrain",
    label_fr: "Bahreïn",
    label_es: "Baréin",
    label_ca: "Bahrain",
    label_it: "Bahrein",
    label_ja: "バーレーン",
  },
  {
    value: "BD",
    label_en: "Bangladesh",
    label_fr: "Bangladesh",
    label_es: "Bangladés",
    label_ca: "Bangla Desh",
    label_it: "Bangladesh",
    label_ja: "バングラデシュ",
  },
  {
    value: "BB",
    label_en: "Barbados",
    label_fr: "Barbade",
    label_es: "Barbados",
    label_ca: "Barbados",
    label_it: "Barbados",
    label_ja: "バルバドス",
  },
  {
    value: "BY",
    label_en: "Belarus",
    label_fr: "Biélorussie",
    label_es: "Bielorrusia",
    label_ca: "Bielorússia",
    label_it: "Bielorussia",
    label_ja: "ベラルーシ",
  },
  {
    value: "BE",
    label_en: "Belgium",
    label_fr: "Belgique",
    label_es: "Bélgica",
    label_ca: "Bèlgica",
    label_it: "Belgio",
    label_ja: "ベルギー",
  },
  {
    value: "BZ",
    label_en: "Belize",
    label_fr: "Belize",
    label_es: "Belice",
    label_ca: "Belize",
    label_it: "Belize",
    label_ja: "ベリーズ",
  },
  {
    value: "BJ",
    label_en: "Benin",
    label_fr: "Bénin",
    label_es: "Benín",
    label_ca: "Benín",
    label_it: "Benin",
    label_ja: "ベナン",
  },
  {
    value: "BM",
    label_en: "Bermuda",
    label_fr: "Bermudes",
    label_es: "Bermudas",
    label_ca: "Bermudes",
    label_it: "Bermuda",
    label_ja: "バミューダ",
  },
  {
    value: "BT",
    label_en: "Bhutan",
    label_fr: "Bhoutan",
    label_es: "Bután",
    label_ca: "Bhutan",
    label_it: "Bhutan",
    label_ja: "ブータン",
  },
  {
    value: "BO",
    label_en: "Bolivia (Plurinational State of)",
    label_fr: "Bolivie (État plurinational de)",
    label_es: "Bolivia (Estado Plurinacional de)",
    label_ca: "Bolívia (Estat Plurinacional de)",
    label_it: "Bolivia (Stato Plurinazionale di)",
    label_ja: "ボリビア（多民族国）",
  },
  {
    value: "BQ",
    label_en: "Bonaire, Sint Eustatius and Saba",
    label_fr: "Bonaire, Saint-Eustache et Saba",
    label_es: "Bonaire, San Eustaquio y Saba",
    label_ca: "Bonaire, Sint Eustatius i Saba",
    label_it: "Bonaire, Sant'Eustazio e Saba",
    label_ja: "ボネール、シント・ユースタティウスおよびサバ",
  },
  {
    value: "BA",
    label_en: "Bosnia and Herzegovina",
    label_fr: "Bosnie-Herzégovine",
    label_es: "Bosnia y Herzegovina",
    label_ca: "Bòsnia i Hercegovina",
    label_it: "Bosnia ed Erzegovina",
    label_ja: "ボスニア・ヘルツェゴビナ",
  },
  {
    value: "BW",
    label_en: "Botswana",
    label_fr: "Botswana",
    label_es: "Botsuana",
    label_ca: "Botswana",
    label_it: "Botswana",
    label_ja: "ボツワナ",
  },
  {
    value: "BV",
    label_en: "Bouvet Island",
    label_fr: "Île Bouvet",
    label_es: "Isla Bouvet",
    label_ca: "Illa Bouvet",
    label_it: "Isola Bouvet",
    label_ja: "ブーベ島",
  },
  {
    value: "BR",
    label_en: "Brazil",
    label_fr: "Brésil",
    label_es: "Brasil",
    label_ca: "Brasil",
    label_it: "Brasile",
    label_ja: "ブラジル",
  },
  {
    value: "IO",
    label_en: "British Indian Ocean Territory (the)",
    label_fr: "Territoire britannique de l'océan Indien",
    label_es: "Territorio Británico del Océano Índico",
    label_ca: "Territori Britànic de l'Oceà Índic",
    label_it: "Territorio britannico dell'Oceano Indiano",
    label_ja: "英領インド洋地域",
  },
  {
    value: "BN",
    label_en: "Brunei Darussalam",
    label_fr: "Brunei Darussalam",
    label_es: "Brunéi Darussalam",
    label_ca: "Brunei Darussalam",
    label_it: "Brunei Darussalam",
    label_ja: "ブルネイ・ダルサラーム",
  },
  {
    value: "BG",
    label_en: "Bulgaria",
    label_fr: "Bulgarie",
    label_es: "Bulgaria",
    label_ca: "Bulgària",
    label_it: "Bulgaria",
    label_ja: "ブルガリア",
  },
  {
    value: "BF",
    label_en: "Burkina Faso",
    label_fr: "Burkina Faso",
    label_es: "Burkina Faso",
    label_ca: "Burkina Faso",
    label_it: "Burkina Faso",
    label_ja: "ブルキナファソ",
  },
  {
    value: "BI",
    label_en: "Burundi",
    label_fr: "Burundi",
    label_es: "Burundi",
    label_ca: "Burundi",
    label_it: "Burundi",
    label_ja: "ブルンジ",
  },
  {
    value: "CV",
    label_en: "Cabo Verde",
    label_fr: "Cap-Vert",
    label_es: "Cabo Verde",
    label_ca: "Cap Verd",
    label_it: "Capo Verde",
    label_ja: "カーボベルデ",
  },
  {
    value: "KH",
    label_en: "Cambodia",
    label_fr: "Cambodge",
    label_es: "Camboya",
    label_ca: "Cambodja",
    label_it: "Cambogia",
    label_ja: "カンボジア",
  },
  {
    value: "CM",
    label_en: "Cameroon",
    label_fr: "Cameroun",
    label_es: "Camerún",
    label_ca: "Camerun",
    label_it: "Camerun",
    label_ja: "カメルーン",
  },
  {
    value: "CA",
    label_en: "Canada",
    label_fr: "Canada",
    label_es: "Canadá",
    label_ca: "Canadà",
    label_it: "Canada",
    label_ja: "カナダ",
  },
  {
    value: "KY",
    label_en: "Cayman Islands (the)",
    label_fr: "Îles Caïmans",
    label_es: "Islas Caimán",
    label_ca: "Illes Caiman",
    label_it: "Isole Cayman",
    label_ja: "ケイマン諸島",
  },
  {
    value: "CF",
    label_en: "Central African Republic (the)",
    label_fr: "République centrafricaine",
    label_es: "República Centroafricana",
    label_ca: "República Centreafricana",
    label_it: "Repubblica Centrafricana",
    label_ja: "中央アフリカ共和国",
  },
  {
    value: "TD",
    label_en: "Chad",
    label_fr: "Tchad",
    label_es: "Chad",
    label_ca: "Txad",
    label_it: "Ciad",
    label_ja: "チャド",
  },
  {
    value: "CL",
    label_en: "Chile",
    label_fr: "Chili",
    label_es: "Chile",
    label_ca: "Xile",
    label_it: "Cile",
    label_ja: "チリ",
  },
  {
    value: "CN",
    label_en: "China",
    label_fr: "Chine",
    label_es: "China",
    label_ca: "Xina",
    label_it: "Cina",
    label_ja: "中国",
  },
  {
    value: "CX",
    label_en: "Christmas Island",
    label_fr: "Île Christmas",
    label_es: "Isla de Navidad",
    label_ca: "Illa Christmas",
    label_it: "Isola di Natale",
    label_ja: "クリスマス島",
  },
  {
    value: "CC",
    label_en: "Cocos (Keeling) Islands (the)",
    label_fr: "Îles Cocos",
    label_es: "Islas Cocos",
    label_ca: "Illes Cocos",
    label_it: "Isole Cocos",
    label_ja: "ココス諸島",
  },
  {
    value: "CO",
    label_en: "Colombia",
    label_fr: "Colombie",
    label_es: "Colombia",
    label_ca: "Colòmbia",
    label_it: "Colombia",
    label_ja: "コロンビア",
  },
  {
    value: "KM",
    label_en: "Comoros (the)",
    label_fr: "Comores",
    label_es: "Comoras",
    label_ca: "Comores",
    label_it: "Comore",
    label_ja: "コモロ",
  },
  {
    value: "CD",
    label_en: "Congo (the Democratic Republic of the)",
    label_fr: "Congo (République démocratique du)",
    label_es: "Congo (República Democrática del)",
    label_ca: "Congo (República Democràtica del)",
    label_it: "Congo (Repubblica Democratica del)",
    label_ja: "コンゴ民主共和国",
  },
  {
    value: "CG",
    label_en: "Congo (the)",
    label_fr: "Congo",
    label_es: "Congo",
    label_ca: "Congo",
    label_it: "Congo",
    label_ja: "コンゴ",
  },
  {
    value: "CK",
    label_en: "Cook Islands (the)",
    label_fr: "Îles Cook",
    label_es: "Islas Cook",
    label_ca: "Illes Cook",
    label_it: "Isole Cook",
    label_ja: "クック諸島",
  },
  {
    value: "CR",
    label_en: "Costa Rica",
    label_fr: "Costa Rica",
    label_es: "Costa Rica",
    label_ca: "Costa Rica",
    label_it: "Costa Rica",
    label_ja: "コスタリカ",
  },
  {
    value: "HR",
    label_en: "Croatia",
    label_fr: "Croatie",
    label_es: "Croacia",
    label_ca: "Croàcia",
    label_it: "Croazia",
    label_ja: "クロアチア",
  },
  {
    value: "CU",
    label_en: "Cuba",
    label_fr: "Cuba",
    label_es: "Cuba",
    label_ca: "Cuba",
    label_it: "Cuba",
    label_ja: "キューバ",
  },
  {
    value: "CW",
    label_en: "Curaçao",
    label_fr: "Curaçao",
    label_es: "Curazao",
    label_ca: "Curaçao",
    label_it: "Curaçao",
    label_ja: "キュラソー",
  },
  {
    value: "CY",
    label_en: "Cyprus",
    label_fr: "Chypre",
    label_es: "Chipre",
    label_ca: "Xipre",
    label_it: "Cipro",
    label_ja: "キプロス",
  },
  {
    value: "CZ",
    label_en: "Czechia",
    label_fr: "Tchéquie",
    label_es: "Chequia",
    label_ca: "Txèquia",
    label_it: "Cechia",
    label_ja: "チェコ",
  },
  {
    value: "CI",
    label_en: "Côte d'Ivoire",
    label_fr: "Côte d'Ivoire",
    label_es: "Costa de Marfil",
    label_ca: "Costa d'Ivori",
    label_it: "Costa d'Avorio",
    label_ja: "コートジボワール",
  },
  {
    value: "DK",
    label_en: "Denmark",
    label_fr: "Danemark",
    label_es: "Dinamarca",
    label_ca: "Dinamarca",
    label_it: "Danimarca",
    label_ja: "デンマーク",
  },
  {
    value: "DJ",
    label_en: "Djibouti",
    label_fr: "Djibouti",
    label_es: "Yibuti",
    label_ca: "Djibouti",
    label_it: "Gibuti",
    label_ja: "ジブチ",
  },
  {
    value: "DM",
    label_en: "Dominica",
    label_fr: "Dominique",
    label_es: "Dominica",
    label_ca: "Dominica",
    label_it: "Dominica",
    label_ja: "ドミニカ",
  },
  {
    value: "DO",
    label_en: "Dominican Republic (the)",
    label_fr: "République dominicaine",
    label_es: "República Dominicana",
    label_ca: "República Dominicana",
    label_it: "Repubblica Dominicana",
    label_ja: "ドミニカ共和国",
  },
  {
    value: "EC",
    label_en: "Ecuador",
    label_fr: "Équateur",
    label_es: "Ecuador",
    label_ca: "Equador",
    label_it: "Ecuador",
    label_ja: "エクアドル",
  },
  {
    value: "EG",
    label_en: "Egypt",
    label_fr: "Égypte",
    label_es: "Egipto",
    label_ca: "Egipte",
    label_it: "Egitto",
    label_ja: "エジプト",
  },
  {
    value: "SV",
    label_en: "El Salvador",
    label_fr: "El Salvador",
    label_es: "El Salvador",
    label_ca: "El Salvador",
    label_it: "El Salvador",
    label_ja: "エルサルバドル",
  },
  {
    value: "GQ",
    label_en: "Equatorial Guinea",
    label_fr: "Guinée équatoriale",
    label_es: "Guinea Ecuatorial",
    label_ca: "Guinea Equatorial",
    label_it: "Guinea Equatoriale",
    label_ja: "赤道ギニア",
  },
  {
    value: "ER",
    label_en: "Eritrea",
    label_fr: "Érythrée",
    label_es: "Eritrea",
    label_ca: "Eritrea",
    label_it: "Eritrea",
    label_ja: "エリトリア",
  },
  {
    value: "EE",
    label_en: "Estonia",
    label_fr: "Estonie",
    label_es: "Estonia",
    label_ca: "Estònia",
    label_it: "Estonia",
    label_ja: "エストニア",
  },
  {
    value: "SZ",
    label_en: "Eswatini",
    label_fr: "Eswatini",
    label_es: "Esuatini",
    label_ca: "Eswatini",
    label_it: "Eswatini",
    label_ja: "エスワティニ",
  },
  {
    value: "ET",
    label_en: "Ethiopia",
    label_fr: "Éthiopie",
    label_es: "Etiopía",
    label_ca: "Etiòpia",
    label_it: "Etiopia",
    label_ja: "エチオピア",
  },
  {
    value: "FK",
    label_en: "Falkland Islands (the) [Malvinas]",
    label_fr: "Îles Malouines",
    label_es: "Islas Malvinas",
    label_ca: "Illes Malvines",
    label_it: "Isole Falkland",
    label_ja: "フォークランド諸島（マルビナス）",
  },
  {
    value: "FO",
    label_en: "Faroe Islands (the)",
    label_fr: "Îles Féroé",
    label_es: "Islas Feroe",
    label_ca: "Illes Fèroe",
    label_it: "Isole Faroe",
    label_ja: "フェロー諸島",
  },
  {
    value: "FJ",
    label_en: "Fiji",
    label_fr: "Fidji",
    label_es: "Fiyi",
    label_ca: "Fiji",
    label_it: "Figi",
    label_ja: "フィジー",
  },
  {
    value: "FI",
    label_en: "Finland",
    label_fr: "Finlande",
    label_es: "Finlandia",
    label_ca: "Finlàndia",
    label_it: "Finlandia",
    label_ja: "フィンランド",
  },
  {
    value: "FR",
    label_en: "France",
    label_fr: "France",
    label_es: "Francia",
    label_ca: "França",
    label_it: "Francia",
    label_ja: "フランス",
  },
  {
    value: "GF",
    label_en: "French Guiana",
    label_fr: "Guyane française",
    label_es: "Guayana Francesa",
    label_ca: "Guaiana Francesa",
    label_it: "Guiana Francese",
    label_ja: "フランス領ギアナ",
  },
  {
    value: "PF",
    label_en: "French Polynesia",
    label_fr: "Polynésie française",
    label_es: "Polinesia Francesa",
    label_ca: "Polinèsia Francesa",
    label_it: "Polinesia Francese",
    label_ja: "フランス領ポリネシア",
  },
  {
    value: "TF",
    label_en: "French Southern Territories (the)",
    label_fr: "Terres australes françaises",
    label_es: "Territorios Australes Franceses",
    label_ca: "Territoris Australs Francesos",
    label_it: "Territori Francesi del Sud",
    label_ja: "フランス領南方・南極地域",
  },
  {
    value: "GA",
    label_en: "Gabon",
    label_fr: "Gabon",
    label_es: "Gabón",
    label_ca: "Gabon",
    label_it: "Gabon",
    label_ja: "ガボン",
  },
  {
    value: "GM",
    label_en: "Gambia (the)",
    label_fr: "Gambie",
    label_es: "Gambia",
    label_ca: "Gàmbia",
    label_it: "Gambia",
    label_ja: "ガンビア",
  },
  {
    value: "GE",
    label_en: "Georgia",
    label_fr: "Géorgie",
    label_es: "Georgia",
    label_ca: "Geòrgia",
    label_it: "Georgia",
    label_ja: "ジョージア",
  },
  {
    value: "DE",
    label_en: "Germany",
    label_fr: "Allemagne",
    label_es: "Alemania",
    label_ca: "Alemanya",
    label_it: "Germania",
    label_ja: "ドイツ",
  },
  {
    value: "GH",
    label_en: "Ghana",
    label_fr: "Ghana",
    label_es: "Ghana",
    label_ca: "Ghana",
    label_it: "Ghana",
    label_ja: "ガーナ",
  },
  {
    value: "GI",
    label_en: "Gibraltar",
    label_fr: "Gibraltar",
    label_es: "Gibraltar",
    label_ca: "Gibraltar",
    label_it: "Gibilterra",
    label_ja: "ジブラルタル",
  },
  {
    value: "GR",
    label_en: "Greece",
    label_fr: "Grèce",
    label_es: "Grecia",
    label_ca: "Grècia",
    label_it: "Grecia",
    label_ja: "ギリシャ",
  },
  {
    value: "GL",
    label_en: "Greenland",
    label_fr: "Groenland",
    label_es: "Groenlandia",
    label_ca: "Grenlàndia",
    label_it: "Groenlandia",
    label_ja: "グリーンランド",
  },
  {
    value: "GD",
    label_en: "Grenada",
    label_fr: "Grenade",
    label_es: "Granada",
    label_ca: "Grenada",
    label_it: "Grenada",
    label_ja: "グレナダ",
  },
  {
    value: "GP",
    label_en: "Guadeloupe",
    label_fr: "Guadeloupe",
    label_es: "Guadalupe",
    label_ca: "Guadeloupe",
    label_it: "Guadalupa",
    label_ja: "グアドループ",
  },
  {
    value: "GU",
    label_en: "Guam",
    label_fr: "Guam",
    label_es: "Guam",
    label_ca: "Guam",
    label_it: "Guam",
    label_ja: "グアム",
  },
  {
    value: "GT",
    label_en: "Guatemala",
    label_fr: "Guatemala",
    label_es: "Guatemala",
    label_ca: "Guatemala",
    label_it: "Guatemala",
    label_ja: "グアテマラ",
  },
  {
    value: "GG",
    label_en: "Guernsey",
    label_fr: "Guernesey",
    label_es: "Guernsey",
    label_ca: "Guernsey",
    label_it: "Guernsey",
    label_ja: "ガーンジー",
  },
  {
    value: "GN",
    label_en: "Guinea",
    label_fr: "Guinée",
    label_es: "Guinea",
    label_ca: "Guinea",
    label_it: "Guinea",
    label_ja: "ギニア",
  },
  {
    value: "GW",
    label_en: "Guinea-Bissau",
    label_fr: "Guinée-Bissau",
    label_es: "Guinea-Bisáu",
    label_ca: "Guinea-Bissau",
    label_it: "Guinea-Bissau",
    label_ja: "ギニアビサウ",
  },
  {
    value: "GY",
    label_en: "Guyana",
    label_fr: "Guyana",
    label_es: "Guyana",
    label_ca: "Guyana",
    label_it: "Guyana",
    label_ja: "ガイアナ",
  },
  {
    value: "HT",
    label_en: "Haiti",
    label_fr: "Haïti",
    label_es: "Haití",
    label_ca: "Haití",
    label_it: "Haiti",
    label_ja: "ハイチ",
  },
  {
    value: "HM",
    label_en: "Heard Island and McDonald Islands",
    label_fr: "Île Heard et îles McDonald",
    label_es: "Islas Heard y McDonald",
    label_ca: "Illes Heard i McDonald",
    label_it: "Isola Heard e Isole McDonald",
    label_ja: "ハード島とマクドナルド諸島",
  },
  {
    value: "VA",
    label_en: "Holy See (the)",
    label_fr: "Saint-Siège",
    label_es: "Santa Sede",
    label_ca: "Santa Seu",
    label_it: "Santa Sede",
    label_ja: "バチカン市国",
  },
  {
    value: "HN",
    label_en: "Honduras",
    label_fr: "Honduras",
    label_es: "Honduras",
    label_ca: "Hondures",
    label_it: "Honduras",
    label_ja: "ホンジュラス",
  },
  {
    value: "HK",
    label_en: "Hong Kong",
    label_fr: "Hong Kong",
    label_es: "Hong Kong",
    label_ca: "Hong Kong",
    label_it: "Hong Kong",
    label_ja: "香港",
  },
  {
    value: "HU",
    label_en: "Hungary",
    label_fr: "Hongrie",
    label_es: "Hungría",
    label_ca: "Hongria",
    label_it: "Ungheria",
    label_ja: "ハンガリー",
  },
  {
    value: "IS",
    label_en: "Iceland",
    label_fr: "Islande",
    label_es: "Islandia",
    label_ca: "Islàndia",
    label_it: "Islanda",
    label_ja: "アイスランド",
  },
  {
    value: "IN",
    label_en: "India",
    label_fr: "Inde",
    label_es: "India",
    label_ca: "Índia",
    label_it: "India",
    label_ja: "インド",
  },
  {
    value: "ID",
    label_en: "Indonesia",
    label_fr: "Indonésie",
    label_es: "Indonesia",
    label_ca: "Indonèsia",
    label_it: "Indonesia",
    label_ja: "インドネシア",
  },
  {
    value: "IR",
    label_en: "Iran (Islamic Republic of)",
    label_fr: "Iran (République Islamique d')",
    label_es: "Irán (República Islámica del)",
    label_ca: "Iran (República Islàmica de)",
    label_it: "Iran (Repubblica Islamica)",
    label_ja: "イラン（イスラム共和国）",
  },
  {
    value: "IQ",
    label_en: "Iraq",
    label_fr: "Irak",
    label_es: "Irak",
    label_ca: "Iraq",
    label_it: "Iraq",
    label_ja: "イラク",
  },
  {
    value: "IE",
    label_en: "Ireland",
    label_fr: "Irlande",
    label_es: "Irlanda",
    label_ca: "Irlanda",
    label_it: "Irlanda",
    label_ja: "アイルランド",
  },
  {
    value: "IM",
    label_en: "Isle of Man",
    label_fr: "Île de Man",
    label_es: "Isla de Man",
    label_ca: "Illa de Man",
    label_it: "Isola di Man",
    label_ja: "マン島",
  },
  {
    value: "IL",
    label_en: "Israel",
    label_fr: "Israël",
    label_es: "Israel",
    label_ca: "Israel",
    label_it: "Israele",
    label_ja: "イスラエル",
  },
  {
    value: "IT",
    label_en: "Italy",
    label_fr: "Italie",
    label_es: "Italia",
    label_ca: "Itàlia",
    label_it: "Italia",
    label_ja: "イタリア",
  },
  {
    value: "JM",
    label_en: "Jamaica",
    label_fr: "Jamaïque",
    label_es: "Jamaica",
    label_ca: "Jamaica",
    label_it: "Giamaica",
    label_ja: "ジャマイカ",
  },
  {
    value: "JP",
    label_en: "Japan",
    label_fr: "Japon",
    label_es: "Japón",
    label_ca: "Japó",
    label_it: "Giappone",
    label_ja: "日本",
  },
  {
    value: "JE",
    label_en: "Jersey",
    label_fr: "Jersey",
    label_es: "Jersey",
    label_ca: "Jersey",
    label_it: "Jersey",
    label_ja: "ジャージー",
  },
  {
    value: "JO",
    label_en: "Jordan",
    label_fr: "Jordanie",
    label_es: "Jordania",
    label_ca: "Jordània",
    label_it: "Giordania",
    label_ja: "ヨルダン",
  },
  {
    value: "KZ",
    label_en: "Kazakhstan",
    label_fr: "Kazakhstan",
    label_es: "Kazajistán",
    label_ca: "Kazakhstan",
    label_it: "Kazakistan",
    label_ja: "カザフスタン",
  },
  {
    value: "KE",
    label_en: "Kenya",
    label_fr: "Kenya",
    label_es: "Kenia",
    label_ca: "Kenya",
    label_it: "Kenya",
    label_ja: "ケニア",
  },
  {
    value: "KI",
    label_en: "Kiribati",
    label_fr: "Kiribati",
    label_es: "Kiribati",
    label_ca: "Kiribati",
    label_it: "Kiribati",
    label_ja: "キリバス",
  },
  {
    value: "KP",
    label_en: "Korea (the Democratic People's Republic of)",
    label_fr: "Corée du Nord",
    label_es: "Corea del Norte",
    label_ca: "Corea del Nord",
    label_it: "Corea del Nord",
    label_ja: "北朝鮮",
  },
  {
    value: "KR",
    label_en: "Korea (the Republic of)",
    label_fr: "Corée du Sud",
    label_es: "Corea del Sur",
    label_ca: "Corea del Sud",
    label_it: "Corea del Sud",
    label_ja: "韓国",
  },
  {
    value: "KW",
    label_en: "Kuwait",
    label_fr: "Koweït",
    label_es: "Kuwait",
    label_ca: "Kuwait",
    label_it: "Kuwait",
    label_ja: "クウェート",
  },
  {
    value: "KG",
    label_en: "Kyrgyzstan",
    label_fr: "Kirghizistan",
    label_es: "Kirguistán",
    label_ca: "Kirguizistan",
    label_it: "Kirghizistan",
    label_ja: "キルギス",
  },
  {
    value: "LA",
    label_en: "Lao People's Democratic Republic (the)",
    label_fr: "Laos",
    label_es: "Laos",
    label_ca: "Laos",
    label_it: "Laos",
    label_ja: "ラオス",
  },
  {
    value: "LV",
    label_en: "Latvia",
    label_fr: "Lettonie",
    label_es: "Letonia",
    label_ca: "Letònia",
    label_it: "Lettonia",
    label_ja: "ラトビア",
  },
  {
    value: "LB",
    label_en: "Lebanon",
    label_fr: "Liban",
    label_es: "Líbano",
    label_ca: "Líban",
    label_it: "Libano",
    label_ja: "レバノン",
  },
  {
    value: "LS",
    label_en: "Lesotho",
    label_fr: "Lesotho",
    label_es: "Lesoto",
    label_ca: "Lesotho",
    label_it: "Lesotho",
    label_ja: "レソト",
  },
  {
    value: "LR",
    label_en: "Liberia",
    label_fr: "Libéria",
    label_es: "Liberia",
    label_ca: "Libèria",
    label_it: "Liberia",
    label_ja: "リベリア",
  },
  {
    value: "LY",
    label_en: "Libya",
    label_fr: "Libye",
    label_es: "Libia",
    label_ca: "Líbia",
    label_it: "Libia",
    label_ja: "リビア",
  },
  {
    value: "LI",
    label_en: "Liechtenstein",
    label_fr: "Liechtenstein",
    label_es: "Liechtenstein",
    label_ca: "Liechtenstein",
    label_it: "Liechtenstein",
    label_ja: "リヒテンシュタイン",
  },
  {
    value: "LT",
    label_en: "Lithuania",
    label_fr: "Lituanie",
    label_es: "Lituania",
    label_ca: "Lituània",
    label_it: "Lituania",
    label_ja: "リトアニア",
  },
  {
    value: "LU",
    label_en: "Luxembourg",
    label_fr: "Luxembourg",
    label_es: "Luxemburgo",
    label_ca: "Luxemburg",
    label_it: "Lussemburgo",
    label_ja: "ルクセンブルク",
  },
  {
    value: "MO",
    label_en: "Macao",
    label_fr: "Macao",
    label_es: "Macao",
    label_ca: "Macau",
    label_it: "Macao",
    label_ja: "マカオ",
  },
  {
    value: "MG",
    label_en: "Madagascar",
    label_fr: "Madagascar",
    label_es: "Madagascar",
    label_ca: "Madagascar",
    label_it: "Madagascar",
    label_ja: "マダガスカル",
  },
  {
    value: "MW",
    label_en: "Malawi",
    label_fr: "Malawi",
    label_es: "Malaui",
    label_ca: "Malawi",
    label_it: "Malawi",
    label_ja: "マラウイ",
  },
  {
    value: "MY",
    label_en: "Malaysia",
    label_fr: "Malaisie",
    label_es: "Malasia",
    label_ca: "Malàisia",
    label_it: "Malesia",
    label_ja: "マレーシア",
  },
  {
    value: "MV",
    label_en: "Maldives",
    label_fr: "Maldives",
    label_es: "Maldivas",
    label_ca: "Maldives",
    label_it: "Maldive",
    label_ja: "モルディブ",
  },
  {
    value: "ML",
    label_en: "Mali",
    label_fr: "Mali",
    label_es: "Malí",
    label_ca: "Mali",
    label_it: "Mali",
    label_ja: "マリ",
  },
  {
    value: "MT",
    label_en: "Malta",
    label_fr: "Malte",
    label_es: "Malta",
    label_ca: "Malta",
    label_it: "Malta",
    label_ja: "マルタ",
  },
  {
    value: "MH",
    label_en: "Marshall Islands",
    label_fr: "Îles Marshall",
    label_es: "Islas Marshall",
    label_ca: "Illes Marshall",
    label_it: "Isole Marshall",
    label_ja: "マーシャル諸島",
  },
  {
    value: "MQ",
    label_en: "Martinique",
    label_fr: "Martinique",
    label_es: "Martinica",
    label_ca: "Martinica",
    label_it: "Martinica",
    label_ja: "マルティニーク",
  },
  {
    value: "MR",
    label_en: "Mauritania",
    label_fr: "Mauritanie",
    label_es: "Mauritania",
    label_ca: "Mauritània",
    label_it: "Mauritania",
    label_ja: "モーリタニア",
  },
  {
    value: "MU",
    label_en: "Mauritius",
    label_fr: "Maurice",
    label_es: "Mauricio",
    label_ca: "Maurici",
    label_it: "Mauritius",
    label_ja: "モーリシャス",
  },
  {
    value: "YT",
    label_en: "Mayotte",
    label_fr: "Mayotte",
    label_es: "Mayotte",
    label_ca: "Mayotte",
    label_it: "Mayotte",
    label_ja: "マヨット",
  },
  {
    value: "MX",
    label_en: "Mexico",
    label_fr: "Mexique",
    label_es: "México",
    label_ca: "Mèxic",
    label_it: "Messico",
    label_ja: "メキシコ",
  },
  {
    value: "FM",
    label_en: "Micronesia",
    label_fr: "Micronésie",
    label_es: "Micronesia",
    label_ca: "Micronèsia",
    label_it: "Micronesia",
    label_ja: "ミクロネシア",
  },
  {
    value: "MD",
    label_en: "Moldova",
    label_fr: "Moldavie",
    label_es: "Moldavia",
    label_ca: "Moldàvia",
    label_it: "Moldavia",
    label_ja: "モルドバ",
  },
  {
    value: "MC",
    label_en: "Monaco",
    label_fr: "Monaco",
    label_es: "Mónaco",
    label_ca: "Mònaco",
    label_it: "Monaco",
    label_ja: "モナコ",
  },
  {
    value: "MN",
    label_en: "Mongolia",
    label_fr: "Mongolie",
    label_es: "Mongolia",
    label_ca: "Mongòlia",
    label_it: "Mongolia",
    label_ja: "モンゴル",
  },
  {
    value: "ME",
    label_en: "Montenegro",
    label_fr: "Monténégro",
    label_es: "Montenegro",
    label_ca: "Montenegro",
    label_it: "Montenegro",
    label_ja: "モンテネグロ",
  },
  {
    value: "MS",
    label_en: "Montserrat",
    label_fr: "Montserrat",
    label_es: "Montserrat",
    label_ca: "Montserrat",
    label_it: "Montserrat",
    label_ja: "モントセラト",
  },
  {
    value: "MA",
    label_en: "Morocco",
    label_fr: "Maroc",
    label_es: "Marruecos",
    label_ca: "Marroc",
    label_it: "Marocco",
    label_ja: "モロッコ",
  },
  {
    value: "MZ",
    label_en: "Mozambique",
    label_fr: "Mozambique",
    label_es: "Mozambique",
    label_ca: "Moçambic",
    label_it: "Mozambico",
    label_ja: "モザンビーク",
  },
  {
    value: "MM",
    label_en: "Myanmar",
    label_fr: "Myanmar",
    label_es: "Myanmar",
    label_ca: "Myanmar",
    label_it: "Myanmar",
    label_ja: "ミャンマー",
  },
  {
    value: "NA",
    label_en: "Namibia",
    label_fr: "Namibie",
    label_es: "Namibia",
    label_ca: "Namíbia",
    label_it: "Namibia",
    label_ja: "ナミビア",
  },
  {
    value: "NR",
    label_en: "Nauru",
    label_fr: "Nauru",
    label_es: "Nauru",
    label_ca: "Nauru",
    label_it: "Nauru",
    label_ja: "ナウル",
  },
  {
    value: "NP",
    label_en: "Nepal",
    label_fr: "Népal",
    label_es: "Nepal",
    label_ca: "Nepal",
    label_it: "Nepal",
    label_ja: "ネパール",
  },
  {
    value: "NL",
    label_en: "Netherlands",
    label_fr: "Pays-Bas",
    label_es: "Países Bajos",
    label_ca: "Països Baixos",
    label_it: "Paesi Bassi",
    label_ja: "オランダ",
  },
  {
    value: "NC",
    label_en: "New Caledonia",
    label_fr: "Nouvelle-Calédonie",
    label_es: "Nueva Caledonia",
    label_ca: "Nova Caledònia",
    label_it: "Nuova Caledonia",
    label_ja: "ニューカレドニア",
  },
  {
    value: "NZ",
    label_en: "New Zealand",
    label_fr: "Nouvelle-Zélande",
    label_es: "Nueva Zelanda",
    label_ca: "Nova Zelanda",
    label_it: "Nuova Zelanda",
    label_ja: "ニュージーランド",
  },
  {
    value: "NI",
    label_en: "Nicaragua",
    label_fr: "Nicaragua",
    label_es: "Nicaragua",
    label_ca: "Nicaragua",
    label_it: "Nicaragua",
    label_ja: "ニカラグア",
  },
  {
    value: "NE",
    label_en: "Niger (the)",
    label_fr: "Niger",
    label_es: "Níger",
    label_ca: "Níger",
    label_it: "Niger",
    label_ja: "ニジェール",
  },
  {
    value: "NG",
    label_en: "Nigeria",
    label_fr: "Nigéria",
    label_es: "Nigeria",
    label_ca: "Nigèria",
    label_it: "Nigeria",
    label_ja: "ナイジェリア",
  },
  {
    value: "NU",
    label_en: "Niue",
    label_fr: "Niue",
    label_es: "Niue",
    label_ca: "Niue",
    label_it: "Niue",
    label_ja: "ニウエ",
  },
  {
    value: "NF",
    label_en: "Norfolk Island",
    label_fr: "Île Norfolk",
    label_es: "Isla Norfolk",
    label_ca: "Illa Norfolk",
    label_it: "Isola Norfolk",
    label_ja: "ノーフォーク島",
  },
  {
    value: "MP",
    label_en: "Northern Mariana Islands",
    label_fr: "Îles Mariannes du Nord",
    label_es: "Islas Marianas del Norte",
    label_ca: "Illes Mariannes del Nord",
    label_it: "Isole Marianne Settentrionali",
    label_ja: "北マリアナ諸島",
  },
  {
    value: "NO",
    label_en: "Norway",
    label_fr: "Norvège",
    label_es: "Noruega",
    label_ca: "Noruega",
    label_it: "Norvegia",
    label_ja: "ノルウェー",
  },
  {
    value: "OM",
    label_en: "Oman",
    label_fr: "Oman",
    label_es: "Omán",
    label_ca: "Oman",
    label_it: "Oman",
    label_ja: "オマーン",
  },
  {
    value: "PK",
    label_en: "Pakistan",
    label_fr: "Pakistan",
    label_es: "Pakistán",
    label_ca: "Pakistan",
    label_it: "Pakistan",
    label_ja: "パキスタン",
  },
  {
    value: "PW",
    label_en: "Palau",
    label_fr: "Palaos",
    label_es: "Palaos",
    label_ca: "Palaos",
    label_it: "Palau",
    label_ja: "パラオ",
  },
  {
    value: "PS",
    label_en: "Palestine",
    label_fr: "Palestine",
    label_es: "Palestina",
    label_ca: "Palestina",
    label_it: "Palestina",
    label_ja: "パレスチナ",
  },
  {
    value: "PA",
    label_en: "Panama",
    label_fr: "Panama",
    label_es: "Panamá",
    label_ca: "Panamà",
    label_it: "Panama",
    label_ja: "パナマ",
  },
  {
    value: "PG",
    label_en: "Papua New Guinea",
    label_fr: "Papouasie-Nouvelle-Guinée",
    label_es: "Papúa Nueva Guinea",
    label_ca: "Papua Nova Guinea",
    label_it: "Papua Nuova Guinea",
    label_ja: "パプアニューギニア",
  },
  {
    value: "PY",
    label_en: "Paraguay",
    label_fr: "Paraguay",
    label_es: "Paraguay",
    label_ca: "Paraguai",
    label_it: "Paraguay",
    label_ja: "パラグアイ",
  },
  {
    value: "PE",
    label_en: "Peru",
    label_fr: "Pérou",
    label_es: "Perú",
    label_ca: "Perú",
    label_it: "Perù",
    label_ja: "ペルー",
  },
  {
    value: "PH",
    label_en: "Philippines (the)",
    label_fr: "Philippines",
    label_es: "Filipinas",
    label_ca: "Filipines",
    label_it: "Filippine",
    label_ja: "フィリピン",
  },
  {
    value: "PN",
    label_en: "Pitcairn",
    label_fr: "Pitcairn",
    label_es: "Pitcairn",
    label_ca: "Pitcairn",
    label_it: "Pitcairn",
    label_ja: "ピトケアン",
  },
  {
    value: "PL",
    label_en: "Poland",
    label_fr: "Pologne",
    label_es: "Polonia",
    label_ca: "Polònia",
    label_it: "Polonia",
    label_ja: "ポーランド",
  },
  {
    value: "PT",
    label_en: "Portugal",
    label_fr: "Portugal",
    label_es: "Portugal",
    label_ca: "Portugal",
    label_it: "Portogallo",
    label_ja: "ポルトガル",
  },
  {
    value: "PR",
    label_en: "Puerto Rico",
    label_fr: "Porto Rico",
    label_es: "Puerto Rico",
    label_ca: "Puerto Rico",
    label_it: "Porto Rico",
    label_ja: "プエルトリコ",
  },
  {
    value: "QA",
    label_en: "Qatar",
    label_fr: "Qatar",
    label_es: "Catar",
    label_ca: "Qatar",
    label_it: "Qatar",
    label_ja: "カタール",
  },
  {
    value: "MK",
    label_en: "Republic of North Macedonia",
    label_fr: "République de Macédoine du Nord",
    label_es: "República de Macedonia del Norte",
    label_ca: "República de Macedònia del Nord",
    label_it: "Repubblica della Macedonia del Nord",
    label_ja: "北マケドニア共和国",
  },
  {
    value: "RO",
    label_en: "Romania",
    label_fr: "Roumanie",
    label_es: "Rumanía",
    label_ca: "Romania",
    label_it: "Romania",
    label_ja: "ルーマニア",
  },
  {
    value: "RU",
    label_en: "Russian Federation (the)",
    label_fr: "Fédération de Russie",
    label_es: "Federación de Rusia",
    label_ca: "Federació de Rússia",
    label_it: "Federazione Russa",
    label_ja: "ロシア連邦",
  },
  {
    value: "RW",
    label_en: "Rwanda",
    label_fr: "Rwanda",
    label_es: "Ruanda",
    label_ca: "Rwanda",
    label_it: "Ruanda",
    label_ja: "ルワンダ",
  },
  {
    value: "RE",
    label_en: "Réunion",
    label_fr: "La Réunion",
    label_es: "Reunión",
    label_ca: "Reunió",
    label_it: "Riunione",
    label_ja: "レユニオン",
  },
  {
    value: "BL",
    label_en: "Saint Barthélemy",
    label_fr: "Saint-Barthélemy",
    label_es: "San Bartolomé",
    label_ca: "Sant Bartomeu",
    label_it: "San Bartolomeo",
    label_ja: "サン・バルテルミー",
  },
  {
    value: "SH",
    label_en: "Saint Helena, Ascension and Tristan da Cunha",
    label_fr: "Sainte-Hélène, Ascension et Tristan da Cunha",
    label_es: "Santa Elena, Ascensión y Tristán de Acuña",
    label_ca: "Santa Helena, Ascensió i Tristán de Cunha",
    label_it: "Sant'Elena, Ascensione e Tristan da Cunha",
    label_ja: "セントヘレナ、アセンションおよびトリスタンダクーニャ",
  },
  {
    value: "KN",
    label_en: "Saint Kitts and Nevis",
    label_fr: "Saint-Christophe-et-Niévès",
    label_es: "San Cristóbal y Nieves",
    label_ca: "Saint Kitts i Nevis",
    label_it: "Saint Kitts e Nevis",
    label_ja: "セントクリストファー・ネイビス",
  },
  {
    value: "LC",
    label_en: "Saint Lucia",
    label_fr: "Sainte-Lucie",
    label_es: "Santa Lucía",
    label_ca: "Santa Llúcia",
    label_it: "Santa Lucia",
    label_ja: "セントルシア",
  },
  {
    value: "MF",
    label_en: "Saint Martin (French part)",
    label_fr: "Saint-Martin (partie française)",
    label_es: "San Martín (parte francesa)",
    label_ca: "Saint Martin (part francesa)",
    label_it: "Saint-Martin (parte francese)",
    label_ja: "サン・マルタン（フランス領）",
  },
  {
    value: "PM",
    label_en: "Saint Pierre and Miquelon",
    label_fr: "Saint-Pierre-et-Miquelon",
    label_es: "San Pedro y Miquelón",
    label_ca: "Saint Pierre i Miquelon",
    label_it: "Saint-Pierre e Miquelon",
    label_ja: "サンピエール・ミクロン",
  },
  {
    value: "VC",
    label_en: "Saint Vincent and the Grenadines",
    label_fr: "Saint-Vincent-et-les-Grenadines",
    label_es: "San Vicente y las Granadinas",
    label_ca: "Sant Vicenç i les Grenadines",
    label_it: "Saint Vincent e Grenadine",
    label_ja: "セントビンセントおよびグレナディーン諸島",
  },
  {
    value: "WS",
    label_en: "Samoa",
    label_fr: "Samoa",
    label_es: "Samoa",
    label_ca: "Samoa",
    label_it: "Samoa",
    label_ja: "サモア",
  },
  {
    value: "SM",
    label_en: "San Marino",
    label_fr: "Saint-Marin",
    label_es: "San Marino",
    label_ca: "San Marino",
    label_it: "San Marino",
    label_ja: "サンマリノ",
  },
  {
    value: "ST",
    label_en: "Sao Tome and Principe",
    label_fr: "Sao Tomé-et-Principe",
    label_es: "Santo Tomé y Príncipe",
    label_ca: "São Tomé i Príncipe",
    label_it: "Sao Tome e Principe",
    label_ja: "サントメ・プリンシペ",
  },
  {
    value: "SA",
    label_en: "Saudi Arabia",
    label_fr: "Arabie Saoudite",
    label_es: "Arabia Saudita",
    label_ca: "Aràbia Saudita",
    label_it: "Arabia Saudita",
    label_ja: "サウジアラビア",
  },
  {
    value: "SN",
    label_en: "Senegal",
    label_fr: "Sénégal",
    label_es: "Senegal",
    label_ca: "Senegal",
    label_it: "Senegal",
    label_ja: "セネガル",
  },
  {
    value: "RS",
    label_en: "Serbia",
    label_fr: "Serbie",
    label_es: "Serbia",
    label_ca: "Sèrbia",
    label_it: "Serbia",
    label_ja: "セルビア",
  },
  {
    value: "SC",
    label_en: "Seychelles",
    label_fr: "Seychelles",
    label_es: "Seychelles",
    label_ca: "Seychelles",
    label_it: "Seychelles",
    label_ja: "セーシェル",
  },
  {
    value: "SL",
    label_en: "Sierra Leone",
    label_fr: "Sierra Leone",
    label_es: "Sierra Leona",
    label_ca: "Sierra Leone",
    label_it: "Sierra Leone",
    label_ja: "シエラレオネ",
  },
  {
    value: "SG",
    label_en: "Singapore",
    label_fr: "Singapour",
    label_es: "Singapur",
    label_ca: "Singapur",
    label_it: "Singapore",
    label_ja: "シンガポール",
  },
  {
    value: "SX",
    label_en: "Sint Maarten (Dutch part)",
    label_fr: "Saint-Martin (partie néerlandaise)",
    label_es: "San Martín (parte neerlandesa)",
    label_ca: "Saint Martin (part neerlandesa)",
    label_it: "Saint-Martin (parte olandese)",
    label_ja: "サン・マルタン（オランダ領）",
  },
  {
    value: "SK",
    label_en: "Slovakia",
    label_fr: "Slovaquie",
    label_es: "Eslovaquia",
    label_ca: "Eslovàquia",
    label_it: "Slovacchia",
    label_ja: "スロバキア",
  },
  {
    value: "SI",
    label_en: "Slovenia",
    label_fr: "Slovénie",
    label_es: "Eslovenia",
    label_ca: "Eslovènia",
    label_it: "Slovenia",
    label_ja: "スロベニア",
  },
  {
    value: "SB",
    label_en: "Solomon Islands",
    label_fr: "Îles Salomon",
    label_es: "Islas Salomón",
    label_ca: "Illes Salomó",
    label_it: "Isole Salomone",
    label_ja: "ソロモン諸島",
  },
  {
    value: "SO",
    label_en: "Somalia",
    label_fr: "Somalie",
    label_es: "Somalia",
    label_ca: "Somàlia",
    label_it: "Somalia",
    label_ja: "ソマリア",
  },
  {
    value: "ZA",
    label_en: "South Africa",
    label_fr: "Afrique du Sud",
    label_es: "Sudáfrica",
    label_ca: "Sud-àfrica",
    label_it: "Sudafrica",
    label_ja: "南アフリカ",
  },
  {
    value: "GS",
    label_en: "South Georgia and the South Sandwich Islands",
    label_fr: "Géorgie du Sud-et-les îles Sandwich du Sud",
    label_es: "Georgia del Sur e Islas Sandwich del Sur",
    label_ca: "Geòrgia del Sud i Illes Sandwich del Sud",
    label_it: "Georgia del Sud e Isole Sandwich Meridionali",
    label_ja: "サウスジョージア島およびサウスサンドウィッチ諸島",
  },
  {
    value: "SS",
    label_en: "South Sudan",
    label_fr: "Soudan du Sud",
    label_es: "Sudán del Sur",
    label_ca: "Sudan del Sud",
    label_it: "Sud Sudan",
    label_ja: "南スーダン",
  },
  {
    value: "ES",
    label_en: "Spain",
    label_fr: "Espagne",
    label_es: "España",
    label_ca: "Espanya",
    label_it: "Spagna",
    label_ja: "スペイン",
  },
  {
    value: "LK",
    label_en: "Sri Lanka",
    label_fr: "Sri Lanka",
    label_es: "Sri Lanka",
    label_ca: "Sri Lanka",
    label_it: "Sri Lanka",
    label_ja: "スリランカ",
  },
  {
    value: "SD",
    label_en: "Sudan",
    label_fr: "Soudan",
    label_es: "Sudán",
    label_ca: "Sudan",
    label_it: "Sudan",
    label_ja: "スーダン",
  },
  {
    value: "SR",
    label_en: "Suriname",
    label_fr: "Suriname",
    label_es: "Surinam",
    label_ca: "Surinam",
    label_it: "Suriname",
    label_ja: "スリナム",
  },
  {
    value: "SJ",
    label_en: "Svalbard and Jan Mayen",
    label_fr: "Svalbard et Jan Mayen",
    label_es: "Svalbard y Jan Mayen",
    label_ca: "Svalbard i Jan Mayen",
    label_it: "Svalbard e Jan Mayen",
    label_ja: "スバールバル諸島およびヤンマイエン島",
  },
  {
    value: "SE",
    label_en: "Sweden",
    label_fr: "Suède",
    label_es: "Suecia",
    label_ca: "Suècia",
    label_it: "Svezia",
    label_ja: "スウェーデン",
  },
  {
    value: "CH",
    label_en: "Switzerland",
    label_fr: "Suisse",
    label_es: "Suiza",
    label_ca: "Suïssa",
    label_it: "Svizzera",
    label_ja: "スイス",
  },
  {
    value: "SY",
    label_en: "Syrian Arab Republic",
    label_fr: "République arabe syrienne",
    label_es: "República Árabe Siria",
    label_ca: "República Àrab Siriana",
    label_it: "Repubblica Araba Siriana",
    label_ja: "シリア・アラブ共和国",
  },
  {
    value: "TW",
    label_en: "Taiwan (Province of China)",
    label_fr: "Taïwan (Province de Chine)",
    label_es: "Taiwán (Provincia de China)",
    label_ca: "Taiwan (Província de la Xina)",
    label_it: "Taiwan (Provincia della Cina)",
    label_ja: "台湾（中国の省）",
  },
  {
    value: "TJ",
    label_en: "Tajikistan",
    label_fr: "Tadjikistan",
    label_es: "Tayikistán",
    label_ca: "Tadjikistan",
    label_it: "Tagikistan",
    label_ja: "タジキスタン",
  },
  {
    value: "TZ",
    label_en: "Tanzania, United Republic of",
    label_fr: "Tanzanie, République-Unie de",
    label_es: "Tanzania, República Unida de",
    label_ca: "Tanzània, República Unida de",
    label_it: "Tanzania, Repubblica Unita di",
    label_ja: "タンザニア連合共和国",
  },
  {
    value: "TH",
    label_en: "Thailand",
    label_fr: "Thaïlande",
    label_es: "Tailandia",
    label_ca: "Tailàndia",
    label_it: "Thailandia",
    label_ja: "タイ",
  },
  {
    value: "TL",
    label_en: "Timor-Leste",
    label_fr: "Timor-Leste",
    label_es: "Timor-Leste",
    label_ca: "Timor-Leste",
    label_it: "Timor Est",
    label_ja: "東ティモール",
  },
  {
    value: "TG",
    label_en: "Togo",
    label_fr: "Togo",
    label_es: "Togo",
    label_ca: "Togo",
    label_it: "Togo",
    label_ja: "トーゴ",
  },
  {
    value: "TK",
    label_en: "Tokelau",
    label_fr: "Tokelau",
    label_es: "Tokelau",
    label_ca: "Tokelau",
    label_it: "Tokelau",
    label_ja: "トケラウ",
  },
  {
    value: "TO",
    label_en: "Tonga",
    label_fr: "Tonga",
    label_es: "Tonga",
    label_ca: "Tonga",
    label_it: "Tonga",
    label_ja: "トンガ",
  },
  {
    value: "TT",
    label_en: "Trinidad and Tobago",
    label_fr: "Trinité-et-Tobago",
    label_es: "Trinidad y Tobago",
    label_ca: "Trinitat i Tobago",
    label_it: "Trinidad e Tobago",
    label_ja: "トリニダード・トバゴ",
  },
  {
    value: "TN",
    label_en: "Tunisia",
    label_fr: "Tunisie",
    label_es: "Túnez",
    label_ca: "Tunísia",
    label_it: "Tunisia",
    label_ja: "チュニジア",
  },
  {
    value: "TR",
    label_en: "Turkey",
    label_fr: "Turquie",
    label_es: "Turquía",
    label_ca: "Turquia",
    label_it: "Turchia",
    label_ja: "トルコ",
  },
  {
    value: "TM",
    label_en: "Turkmenistan",
    label_fr: "Turkménistan",
    label_es: "Turkmenistán",
    label_ca: "Turkmenistan",
    label_it: "Turkmenistan",
    label_ja: "トルクメニスタン",
  },
  {
    value: "TC",
    label_en: "Turks and Caicos Islands (the)",
    label_fr: "Îles Turques-et-Caïques",
    label_es: "Islas Turcas y Caicos",
    label_ca: "Illes Turks i Caicos",
    label_it: "Isole Turks e Caicos",
    label_ja: "タークス・カイコス諸島",
  },
  {
    value: "TV",
    label_en: "Tuvalu",
    label_fr: "Tuvalu",
    label_es: "Tuvalu",
    label_ca: "Tuvalu",
    label_it: "Tuvalu",
    label_ja: "ツバル",
  },
  {
    value: "UG",
    label_en: "Uganda",
    label_fr: "Ouganda",
    label_es: "Uganda",
    label_ca: "Uganda",
    label_it: "Uganda",
    label_ja: "ウガンダ",
  },
  {
    value: "UA",
    label_en: "Ukraine",
    label_fr: "Ukraine",
    label_es: "Ucrania",
    label_ca: "Ucraïna",
    label_it: "Ucraina",
    label_ja: "ウクライナ",
  },
  {
    value: "AE",
    label_en: "United Arab Emirates (the)",
    label_fr: "Émirats Arabes Unis",
    label_es: "Emiratos Árabes Unidos",
    label_ca: "Emirats Àrabs Units",
    label_it: "Emirati Arabi Uniti",
    label_ja: "アラブ首長国連邦",
  },
  {
    value: "GB",
    label_en: "United Kingdom of Great Britain and Northern Ireland (the)",
    label_fr: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    label_es: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    label_ca: "Regne Unit de la Gran Bretanya i Irlanda del Nord",
    label_it: "Regno Unito di Gran Bretagna e Irlanda del Nord",
    label_ja: "イギリス",
  },
  {
    value: "UM",
    label_en: "United States Minor Outlying Islands (the)",
    label_fr: "Îles mineures éloignées des États-Unis",
    label_es: "Islas Ultramarinas Menores de los Estados Unidos",
    label_ca: "Illes Perifèriques Menors dels Estats Units",
    label_it: "Isole Minori Esterne degli Stati Uniti",
    label_ja: "米領小離島",
  },
  {
    value: "US",
    label_en: "United States of America (the)",
    label_fr: "États-Unis d'Amérique",
    label_es: "Estados Unidos de América",
    label_ca: "Estats Units d'Amèrica",
    label_it: "Stati Uniti d'America",
    label_ja: "アメリカ合衆国",
  },
  {
    value: "UY",
    label_en: "Uruguay",
    label_fr: "Uruguay",
    label_es: "Uruguay",
    label_ca: "Uruguai",
    label_it: "Uruguay",
    label_ja: "ウルグアイ",
  },
  {
    value: "UZ",
    label_en: "Uzbekistan",
    label_fr: "Ouzbékistan",
    label_es: "Uzbekistán",
    label_ca: "Uzbekistan",
    label_it: "Uzbekistan",
    label_ja: "ウズベキスタン",
  },
  {
    value: "VU",
    label_en: "Vanuatu",
    label_fr: "Vanuatu",
    label_es: "Vanuatu",
    label_ca: "Vanuatu",
    label_it: "Vanuatu",
    label_ja: "バヌアツ",
  },
  {
    value: "VE",
    label_en: "Venezuela (Bolivarian Republic of)",
    label_fr: "Venezuela (République bolivarienne du)",
    label_es: "Venezuela (República Bolivariana de)",
    label_ca: "Veneçuela (República Bolivariana de)",
    label_it: "Venezuela (Repubblica Bolivariana del)",
    label_ja: "ベネズエラ（ボリバル共和国）",
  },
  {
    value: "VN",
    label_en: "Viet Nam",
    label_fr: "Viet Nam",
    label_es: "Vietnam",
    label_ca: "Vietnam",
    label_it: "Vietnam",
    label_ja: "ベトナム",
  },
  {
    value: "VG",
    label_en: "Virgin Islands (British)",
    label_fr: "Îles Vierges (britanniques)",
    label_es: "Islas Vírgenes (Británicas)",
    label_ca: "Illes Verges (britàniques)",
    label_it: "Isole Vergini (britanniche)",
    label_ja: "イギリス領ヴァージン諸島",
  },
  {
    value: "VI",
    label_en: "Virgin Islands (U.S.)",
    label_fr: "Îles Vierges (États-Unis)",
    label_es: "Islas Vírgenes (EE. UU.)",
    label_ca: "Illes Verges (Estats Units)",
    label_it: "Isole Vergini (Stati Uniti)",
    label_ja: "アメリカ領ヴァージン諸島",
  },
  {
    value: "WF",
    label_en: "Wallis and Futuna",
    label_fr: "Wallis-et-Futuna",
    label_es: "Wallis y Futuna",
    label_ca: "Wallis i Futuna",
    label_it: "Wallis e Futuna",
    label_ja: "ウォリス・フツナ",
  },
  {
    value: "EH",
    label_en: "Western Sahara",
    label_fr: "Sahara occidental",
    label_es: "Sáhara Occidental",
    label_ca: "Sàhara Occidental",
    label_it: "Sahara Occidentale",
    label_ja: "西サハラ",
  },
  {
    value: "YE",
    label_en: "Yemen",
    label_fr: "Yémen",
    label_es: "Yemen",
    label_ca: "Iemen",
    label_it: "Yemen",
    label_ja: "イエメン",
  },
  {
    value: "ZM",
    label_en: "Zambia",
    label_fr: "Zambie",
    label_es: "Zambia",
    label_ca: "Zàmbia",
    label_it: "Zambia",
    label_ja: "ザンビア",
  },
  {
    value: "ZW",
    label_en: "Zimbabwe",
    label_fr: "Zimbabwe",
    label_es: "Zimbabue",
    label_ca: "Zimbabwe",
    label_it: "Zimbabwe",
    label_ja: "ジンバブエ",
  },
  {
    value: "AX",
    label_en: "Åland Islands",
    label_fr: "Îles Åland",
    label_es: "Islas Åland",
    label_ca: "Illes Åland",
    label_it: "Isole Åland",
    label_ja: "オーランド諸島",
  },
];
