import styled from "styled-components";
export const StyledLangSwitcher = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  transition: all 0.4s;
  cursor: pointer;

  ul {
    position: absolute;
    left: 50%;
    width: 100%;
    min-width: 55px;
    padding: 10px 20px;
    background-color: #000;
    transition: all 0.4s;
    transform: translate(-50%, -20px);
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    list-style: none;
  }

  li {
    text-transform: uppercase;
    color: white;
    padding: 5px 0;

    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  img {
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
    max-width: 10px;
    margin-left: 10px;
  }

  &:hover {
    ul {
      opacity: 1;
      transform: translate(-50%, 0);
      pointer-events: all;
      text-align: center;
    }

    img {
      transform: rotate(90deg);
    }
  }
`;

export const ActiveLang = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0 10px;
  color: white;
`;
