import React from "react";
import { ButtonStyled } from "./button.styled";

type ButtonProps = {
  children: React.ReactNode;
  variant?: "secondary" | "default";
  size?: "small" | "default";
} & React.ComponentPropsWithoutRef<"button">;

export const Button = ({
  variant,
  disabled,
  children,
  size,
  ...props
}: ButtonProps) => {
  return (
    <ButtonStyled disabled={disabled} variant={variant} size={size} {...props}>
      {children}
    </ButtonStyled>
  );
};
