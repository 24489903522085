import { FlexContent } from "components/FlexContent/FlexContent";
import { CounterComponent } from "components/peopleCounter";
import { Spacer } from "components/spacer";
import {
  Label,
  SectionHeadline,
  SmallHeadline,
  SmallParagraph,
} from "components/typography.styles";
import { PeopleCounterWrapper } from "components/views/Booking/styles";
import { BookingContext } from "providers/BookingProvider";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";

export const PeopleSelection = () => {
  const { adultTickets, childTickets, setAdultTickets, setChildTickets } =
    useContext(BookingContext);

  return (
    <>
      <SectionHeadline>
        1. <FormattedMessage defaultMessage="Number of guests" id="K+okA1" />
      </SectionHeadline>
      <Spacer mt={{ xs: 16, md: 32 }} />
      <FlexContent
        mobileDirection="column"
        direction="row"
        justify="space-between"
        fullWidth
        alignItems="flex-start"
      >
        <PeopleCounterWrapper>
          <CounterComponent onClick={setAdultTickets} value={adultTickets}>
            <SmallHeadline>
              <FormattedMessage defaultMessage="Adults" id="rePX4c" />
            </SmallHeadline>
          </CounterComponent>
        </PeopleCounterWrapper>
        <PeopleCounterWrapper>
          <CounterComponent onClick={setChildTickets} value={childTickets}>
            <FlexContent direction="column" alignItems="flex-start">
              <SmallHeadline>
                <FormattedMessage defaultMessage="Children *" id="5rHIKI" />
              </SmallHeadline>
              <Label>
                <FormattedMessage
                  defaultMessage="between 4 and 8 years old"
                  id="/FL0+/"
                />
                <br />
                {"("}
                <FormattedMessage
                  defaultMessage="children under 4 years not allowed"
                  id="cgQCwY"
                />
                {")"}
              </Label>
            </FlexContent>
          </CounterComponent>
        </PeopleCounterWrapper>
      </FlexContent>
      <Spacer mt={{ xs: 5, md: 40 }} />
      <SmallParagraph>
        <FormattedMessage
          defaultMessage="*Children between 4 and 8 years old will benefit from a 50% reduction in
        the ticket rate, redeemable only at check-in after showing proof of age.
        For the enjoyment of all participants, children under 4 years old are
        NOT allowed."
          id="ulCVK4"
        />
      </SmallParagraph>
      <Spacer mt={{ xs: 15 }} />
      <SmallParagraph>
        <FormattedMessage
          defaultMessage="*The show requires the audiences silence. Adults with minors must be
        responsible for avoiding any noise during the show. However, if this is
        not possible, the adult will accompany the minor(s) out of the show hall
        for as long as necessary. Our staff will help them with whatever they
        need."
          id="Ptpn3h"
        />
      </SmallParagraph>
    </>
  );
};
