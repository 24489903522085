import { LOCALES } from "./locales";
import CATALAN from "./translations/ca.json";
import ENGLISH from "./translations/en.json";
import SPANISH from "./translations/es.json";
import FRENCH from "./translations/fr.json";
import ITALIAN from "./translations/it.json";
import JAPANESE from "./translations/ja.json";

export const localeMessages = {
  [LOCALES.ENGLISH]: ENGLISH,
  [LOCALES.CATALAN]: CATALAN,
  [LOCALES.SPANISH]: SPANISH,
  [LOCALES.FRENCH]: FRENCH,
  [LOCALES.ITALIAN]: ITALIAN,
  [LOCALES.JAPANESE]: JAPANESE,
};
