import styled, { css } from "styled-components";

export const StyledDayEvent = styled.div<{
  isActive?: boolean;
  withArrow?: boolean;
  isPromo?: boolean;
}>`
  position: relative;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 2px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.yellow : theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 8px;
  padding: 8px 16px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

  label {
    cursor: pointer;
  }
  ${({ withArrow }) =>
    withArrow &&
    css`
      margin-bottom: 20px;
      margin-right: 15px;
    `};

  ${({ isActive, withArrow }) =>
    isActive &&
    withArrow &&
    css`
      &:after {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${({ theme }) => theme.colors.yellow};
      }
    `};
`;
export const WarningElement = styled.div`
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  svg {
    max-width: 20px;
  }
`;
