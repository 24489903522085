import React from "react";
import { REDSYS_URL } from "../../env";

type PaymentsRedsysProps = {
  signature: string;
  signatureVersion: string;
  merchantParameters: string;
};

export const PaymentsRedsys = React.forwardRef(
  (
    { signature, signatureVersion, merchantParameters }: PaymentsRedsysProps,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <form action={REDSYS_URL} method="POST" style={{ opacity: 0 }}>
      <input type="hidden" name="Ds_Signature" value={signature} />
      <input
        type="hidden"
        name="Ds_SignatureVersion"
        value={signatureVersion}
      />
      <input
        type="hidden"
        name="Ds_MerchantParameters"
        value={merchantParameters}
      />
      <input ref={ref} type="submit" value="Send!" />
    </form>
  ),
);
