export const ContentCa = () => (
  <>
    <h2>Termes i condicions</h2>
    <div>
      <p>
        En compliment de la Llei 34/2002 de Serveis de la Societat de la
        Informació i del Comerç Electrònic (LSSICE), us informem que{" "}
        <a href="https://tablaocordobes.es" target="_self">
          https://tablaocordobes.es
        </a>{" "}
        és un domini d'Internet i una marca registrada per Cadame, S.L. (d'ara
        endavant, Tablao Flamenco Cordobes), C.I.F. B60132263, amb domicili
        social a La Rambla 35 de Barcelona i inscrita en el Reg. Mercantil de
        Barcelona, Folio 182, Tom 24966, inc 1a Fulla B-81666 C. Així mateix,
        TABLAO Flamenco CORDOBES.
      </p>
      <p>
        Condicions generals de contractació entre Tablao Flamenco Cordobes i
        l'usuari:
      </p>
      <ol>
        <li>
          <p>
            L'usuari declara ser major d'edat (més de 18 anys) i disposar de la
            capacitat legal necessària per contractar els serveis oferts al lloc
            web de Tablao Flamenco Cordobes, d'acord amb les condicions
            desglossades a continuació, les quals declara comprendre i acceptar.
          </p>
          <p>
            En cas de contractació per part de menors d'edat, es requereix
            l'autorització dels pares o tutors per poder gaudir del servei
            contractat. L'usuari és l'únic responsable de la veracitat i
            exactitud de les dades proporcionades a Tablao Flamenco Cordobes en
            el procés de reserva.
          </p>
          <p>
            L'usuari es compromet a utilitzar els serveis i continguts al nostre
            lloc web d'acord amb la Llei i les condicions generals i particulars
            dels serveis que ofereix Tablao Flamenco Cordobes en cada moment,
            havent-se d'abstenir de fer servir-los per a:
          </p>
          <p>
            (a) Transmetre a tercers no autoritzats el seu nom d'usuari i la
            seva contrasenya, havent de comunicar immediatament a El Duende by
            Tablao Cordobes l'accés per part d'un usuari no autoritzat a aquesta
            informació.
          </p>
          <p>
            (b) Introduir a la xarxa programes de dades susceptibles de causar
            danys en els sistemes informàtics de Tablao Flamenco Cordobes, els
            seus proveïdors o tercers usuaris de la xarxa interna o d'aquest
            lloc web.
          </p>
          <p>
            (c) Realitzar activitats il·lícites, contràries a la bona fe, a les
            costums, a la moral o a l'ordre públic.
          </p>
          <p>
            (d) Realitzar activitats que constitueixin una infracció de la
            regulació sobre propietat intel·lectual i industrial o qualsevol
            altra norma de l'ordenament jurídic aplicable.
          </p>
          <p>
            (e) Reproduir, duplicar, vendre o explotar amb fins comercials
            qualsevol contingut del lloc web.
          </p>
          <p>
            (f) Difondre continguts de caràcter racista, xenòfob, pornogràfic,
            d'apologia del terrorisme i/o que atemptin contra els drets humans.
          </p>
        </li>
        <li>
          <p>
            En registrar-se a Tablao Flamenco Cordobes, l'usuari triarà un nom
            d'usuari i una contrasenya, que posteriorment li seran confirmats
            per correu electrònic. L'usuari haurà de custodiar adequadament les
            claus i contrasenyes facilitades per al seu accés com a usuari
            registrat, ja que són de caràcter personal i confidencial, sent
            l'usuari responsable del seu ús per part d'una tercera persona a qui
            aquest usuari les hagi facilitades.
          </p>
        </li>
        <li>
          <p>
            L'usuari és responsable de facilitar les seves dades correctament en
            els processos de contractació establerts a la pàgina web de El
            Duende by Tablao Cordobes, havent de prestar especial atenció a la
            introducció de les dades relatives a l'adreça de lliurament dels
            comandaments i documents, així com els noms de les terceres persones
            respecte a les quals introdueixi les seves dades, prèvia
            autorització d'aquestes terceres persones, havent de introduir
            l'usuari en tot moment totes les dades d'identificació de manera
            correcta i tal com apareixen en el DNI o passaport.
          </p>
        </li>
        <li>
          <p>
            Tablao Flamenco Cordobes és titular de la base de dades generada amb
            les dades de caràcter personal subministrades pels usuaris en el
            procés de compra de productes a través de la nostra pàgina web. El
            Duende by Tablao Cordobes es compromet al compliment de la seva
            obligació de secret de les dades de caràcter personal i del seu
            deure de tractar-les amb confidencialitat, i a aquest efecte El
            Duende by Tablao Cordobes adoptarà les mesures necessàries per
            evitar la seva alteració, pèrdua, tractament o accés no autoritzat,
            tenint en compte en tot moment l'estat de la tecnologia d'acord amb
            el previst en la legislació vigent a Espanya en matèria de protecció
            de dades de caràcter personal.
          </p>
          <p>
            L'usuari accepta que les dades personals facilitades en el procés de
            compra dels nostres productes siguin incorporades a un fitxer
            automatitzat, la titularitat del qual correspon a El Duende by
            Tablao Cordobes, amb la finalitat de prestar adequadament el servei
            sol·licitat, donar compliment als requisits de facturació,
            comptabilitat i auditoria de la companyia, realitzar anàlisis
            estadístics, verificar targetes de crèdit i altres targetes de
            pagament, així com realitzar enquestes de satisfacció i
            comunicacions comercials per correu electrònic sobre altres
            productes que es comercialitzen a través de la pàgina web de El
            Duende by Tablao Cordobes i que Tablao Flamenco Cordobes consideri
            que podrien ser del seu interès, per ser similars als contractats.
            En cas que l'usuari no vulgui rebre comunicacions comercials per
            mitjans electrònics pot dirigir-se en qualsevol moment a Tablao
            Flamenco Cordobes enviant un correu electrònic a la següent adreça{" "}
            <a href="mailto:reservas@elduendebarcelona.com" target="_self">
              reservas@elduendebarcelona.com
            </a>
            . L'usuari també podrà exercir els seus drets d'accés, rectificació,
            cancel·lació i oposició mitjançant comunicació escrita, adjuntant
            fotocòpia del DNI o document identificatiu equivalent, davant EL
            DUENDE BY TABLAO CORDOBES, S.L. a La Rambla 33, 08002 (Barcelona).
          </p>
          <p>
            El document en què es formalitzi el contracte relatiu al producte
            contractat serà arxivat en una base de dades accessible únicament
            per TABLAO Flamenco CORDOBES, S.L. com a responsable de la mateixa.
            El client podrà identificar i corregir els errors patits en la
            introducció de dades comunicant-ho a Tablao Flamenco Cordobes,
            utilitzant el tràmit esmentat anteriorment en aquesta clàusula.
          </p>
          <p>
            L'usuari respondrà, en tot cas, de la veracitat de les dades
            facilitades i es responsabilitzarà de comunicar a El Duende by
            Tablao Cordobes qualsevol modificació en les mateixes, reservant-se
            El Duende by Tablao Cordobes el dret a excloure dels serveis
            registrats a tot usuari que hagi facilitat dades falses, sense
            perjudici de les altres accions que procedeixin en Dret.
          </p>
        </li>
        <li>
          <p>
            Tots els continguts, marques, logotips, dibuixos etc. que figuren en
            les webs de Tablao Flamenco Cordobes, estan protegits pels drets de
            propietat intel·lectual i industrial que són expressament reservats
            per Tablao Flamenco Cordobes o, si escau, per les persones o
            empreses que figuren com a autors o titulars dels drets. La violació
            dels anteriors drets serà perseguida d'acord amb la legislació
            vigent.
          </p>
          <p>
            Per tant, queda prohibida la reproducció, explotació, alteració,
            distribució o comunicació pública per qualsevol títol o mitjà de la
            totalitat dels continguts de les pàgines web de El Duende by Tablao
            Cordobes per a usos diferents de la legítima informació o
            contractació pels usuaris dels serveis oferts. En tot cas serà
            necessari el consentiment previ per escrit de Tablao Flamenco
            Cordobes.
          </p>
        </li>
        <li>
          <p>
            Els enllaços amb altres pàgines web que apareixen a les pàgines de
            El Duende by Tablao Cordobes s'ofereixen a efectes merament
            informatius per a l'usuari, per la qual cosa Tablao Flamenco
            Cordobes no es responsabilitza sobre els productes, serveis o
            continguts que s'ofereixin o subministren a les pàgines de destí
            ubicades en un altre domini.
          </p>
        </li>
        <li>
          <p>
            Cookies: A Tablao Flamenco Cordobes recull mitjançant l'ús de
            cookies i utilitza informació dels usuaris per analitzar la seva
            conducta al navegar en aquesta pàgina web i d'aquesta manera
            proporcionar els seus serveis de manera més eficient i introduir
            millores.
          </p>
          <p>
            Una cookie és un petit fitxer de dades que es transfereix al disc
            dur del seu ordinador. Tablao Flamenco Cordobes utilitza la
            tecnologia de cookies per recopilar dades addicionals sobre l'ús del
            lloc web. La navegació per les pàgines web de Tablao Flamenco
            Cordobes implica el consentiment en la instal·lació de les mateixes
            (excepte en la mesura que l'usuari hagi modificat la configuració
            del navegador per rebutjar les cookies).
          </p>
          <p>
            Enllaços: Amb la finalitat de proveir una publicitat més adequada
            als usuaris i millorar les ofertes, Tablao Flamenco Cordobes pot
            conservar informació sobre com l'usuari interactua amb enllaços que
            apareixen a la pàgina web o en les notificacions, redirigint clics o
            per altres mitjans.
          </p>
        </li>
        <li>
          <p>
            Si qualsevol clàusula inclosa en aquestes condicions generals fos
            declarada, total o parcialment, nul·la o ineficaç, aquesta nul·litat
            o ineficàcia afectarà només a aquesta disposició o a la part de la
            mateixa que resulti nul·la o ineficaç, subsistint les condicions
            generals en tot el demés, tenint-se aquesta disposició, o la part de
            la mateixa que resulti afectada, per no posada.
          </p>
        </li>
        <li>
          <p>
            L'usuari accepta que la legislació aplicable al funcionament
            d'aquest servei és l'espanyola. Seran competents per conèixer de les
            divergències que es derivin de la interpretació o aplicació d'aquest
            clausulat els Jutges i Tribunals del domicili del consumidor.
          </p>
        </li>
        <li>
          <p>
            Els preus finals indicats a la pantalla inclouen l'IVA i qualsevol
            altre impost o taxa que sigui d'aplicació, al tipus vigent en el
            moment de la contractació.
          </p>
        </li>
        <li>
          <p>
            Totes les reserves realitzades mitjançant la pàgina web de{" "}
            <a href="https://elduendebarcelona.com/" target="_self">
              elduendebarcelona.com
            </a>{" "}
            podran ser cancel·lades durant els 14 dies hàbils seguents a la data
            de la compra tal com es especifica en la legislació vigent Text
            Refós de la Llei General per a la Defensa dels Consumidors i Usuaris
            i altres lleis complementàries, aprovat pel RD 1/2007. La devolució
            de l'import corresponent serà abonada al client dins dels 30 dies
            seguents la confirmació d'anul·lació de la reserva utilitzant el
            mateix mètode de pagament utilitzat pel client. No s'acceptaran sota
            cap circumstància cancel·lacions o modificacions si la sol·licitud
            de cancel·lació o modificació ha estat rebuda per EL DUENDE BY
            TABLAO CORDOBES, S.L. menys de 48 hores abans de l'hora del servei.
          </p>
        </li>
        <li>
          <p>
            Política Promocional: TABLAO Flamenco CORDOBES, S.L realitza
            regularment promocions que afecten al preu de venda al públic, les
            quals poden ser modificades i/o cancel·lades per part de l'empresa
            en qualsevol moment. Tota promoció que es faci no serà acumulable a
            altres promocions i/o Tarifes Especials vigents. Els productes en
            Promoció, Galas Fi d'Any, Palau, i/o Producte ruleta no podran ser
            cancel·lats i no donaran dret a devolució de l'import de
            l'adquisició del mateix, donat el seu caràcter promocional.
          </p>
        </li>
        <li>
          <p>
            Per a qualsevol suggeriment, consulta, queixa o reclamació l'usuari
            ha de dirigir-se a TABLAO Flamenco CORDOBES, S.L. a l'adreça La
            Rambla 33 08002 Barcelona, o bé mitjançant el telèfon 933175711 o
            mitjançant comunicació electrònica a{" "}
            <a href="mailto:reservas@elduendebarcelona.com" target="_self">
              reservas@elduendebarcelona.com
            </a>
            .
          </p>
        </li>
      </ol>
    </div>
  </>
);
