import { StyledHeader } from "./Header.styled";

type HeaderProps = {
  children: JSX.Element;
  backgroundImage: string;
};
export const Header = ({ children, backgroundImage }: HeaderProps) => (
  <StyledHeader style={{ backgroundImage: `url('${backgroundImage}')` }}>
    {children}
  </StyledHeader>
);
